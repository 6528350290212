import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatDate = (dateString, format = 'h:mm A, DD/MM/YYYY') => {
    return dayjs(dateString).tz('Australia/Sydney').format(format);
};

export {
    formatDate
}