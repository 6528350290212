import React, { Fragment } from 'react';

import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm';

const ForgotPassword = () => {
    return (
        <Fragment>
            <ForgotPasswordForm />
        </Fragment>
    )
};

export default ForgotPassword;