import React, { Fragment, useState, useCallback } from 'react';
import { gql, useMutation } from "@apollo/client";
import ReactS3Uploader from "react-s3-uploader";

import { Typography, makeStyles, Box, FormControl } from '@material-ui/core';

const REQUEST_PRESIGN_URL = gql`
  mutation ($file_name: String!, $file_type: String!) {
    prepareUpload(file_name: $file_name, file_type: $file_type) {
      pre_signed_url
      file_name
      key
      bucket
    }
  }
`;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 200,
    },
}))

const Upload = ({ handleFileInput }) => {
    const classes = useStyles();

    const [isUploaded, setIsUploaded] = useState(false);

    const [requestSignedUrl] = useMutation(REQUEST_PRESIGN_URL);

    const processUpload = useCallback((data, callback) => {
        const { prepareUpload } = data;
        const { pre_signed_url, bucket, key, file_name } = prepareUpload || {};
        const s3Data = {
            signedUrl: pre_signed_url,
        };
        handleFileInput(file_name, bucket, key);
        callback(s3Data);
    }, [handleFileInput]);

    const handleGetSignedUrl = useCallback(async (file, callback) => {
        const { data } = await requestSignedUrl({
            variables: {
                file_name: file.name,
                file_type: file.type,
            },
        });
        await processUpload(data, callback);
    }, [requestSignedUrl, processUpload]);

    const handleSucces = useCallback(() => {
        setIsUploaded(true);
    }, [setIsUploaded]);

    return (
        <Fragment>
            <Box>
                <FormControl className={classes.formControl}>
                    <ReactS3Uploader
                        className={classes.childComponent}
                        getSignedUrl={handleGetSignedUrl}
                        onFinish={handleSucces}
                        uploadRequestHeaders={{
                            "x-amz-acl": "public-read",
                        }}
                        contentDisposition="auto"
                    />
                    {isUploaded && (
                        <Typography>Uploaded</Typography>
                    )}

                </FormControl>
            </Box>
        </Fragment>
    )
};

export default Upload;