import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, FormControl, InputLabel, TextField, IconButton, Box } from '@material-ui/core'
import Select from '../../../FormHelpers/Select';
import { Delete } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  typography: {
    margin: theme.spacing(2),
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  titleElement: {
    margin: '5px',
    padding: '5px',
  },
  form: {
    borderRadius: '25px',
    border: '2px solid rgba(0,0,0,0.2)',
    padding: '20px',
    margin: '10px'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  descriptionField: {
    minWidth: 600,
  },
  marginField: {
    minWidth: 100,
  }
}));

const Entry = ({ entry, updateEntry, removeEntry, tradeOptions, uomOptions }) => {
  const classes = useStyles();

  return (
    <form
      id={`form-id-${entry.id}`}
      className={classes.form}
    >
      <Box className={classes.titleBox}>
        <Typography className={classes.titleElement} display="inline">Entry #{entry.entry_number + 1}</Typography>
        <IconButton
          className={classes.titleElement}
          variant="contained"
          disableElevation={true}
          color="primary"
          onClick={() => removeEntry(entry.id)}
        >
          <Delete />
        </IconButton>
      </Box>
      <Box>
        <FormControl required variant="outlined" size="small" className={classes.formControl}>
          <TextField
            required
            id={`trade-input-${entry.id}`}
            className={classes.textField}
            label="Trade"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            value={entry.trade || ""}
            onChange={({ target: { value } }) => {
              updateEntry(entry.id, 'trade', value);
            }}
            size="small"
            multiline
            error={entry.errors.trade}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <InputLabel>Trade (optional)</InputLabel>
          <Select
            label="Trade (optional)"
            id={`trade-input-${entry.id}`}
            options={tradeOptions}
            value={entry.trade_id || ""}
            onChange={({ target: { value } }) => {
              updateEntry(entry.id, 'trade_id', value);
            }}
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl variant="outlined" className={classes.formControl} size={'medium'}>
          <TextField
            required
            id={`desc-input-${entry.id}`}
            className={classes.descriptionField}
            label="Description"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            value={entry.description || ""}
            onChange={({ target: { value } }) => {
              updateEntry(entry.id, 'description', value);
            }}
            size="small"
            multiline
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl required variant="outlined" size="small" className={classes.formControl}>
          <TextField
            required
            id={`uom-input-${entry.id}`}
            className={classes.textField}
            label="UOM (unit of measure)"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            value={entry.uom || ""}
            onChange={({ target: { value } }) => {
              updateEntry(entry.id, 'uom', value);
            }}
            size="small"
            multiline
            error={entry.errors.uom}
          />
        </FormControl>
        <FormControl required variant="outlined" size="small" className={classes.formControl}>
          <InputLabel>UOM (optional)</InputLabel>
          <Select
            label="UOM (optional)"
            id={`trade-input-${entry.id}`}
            options={uomOptions}
            value={entry.uom_id || ""}
            onChange={({ target: { value } }) => {
              updateEntry(entry.id, 'uom_id', value);
            }}
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            required
            id={`quantity-input-${entry.id}`}
            className={classes.textField}
            label="Quantity"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            value={entry.quantity || ""}
            onChange={({ target: { valueAsNumber } }) => {
              const value = isNaN(valueAsNumber) ? null : valueAsNumber;
              updateEntry(entry.id, 'quantity', value);
            }}
            size="small"
            error={entry.errors.quantity}
          />
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            required
            id={`rate-input-${entry.id}`}
            className={classes.textField}
            label="Rate ($)"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            value={entry.rate || ""}
            onChange={({ target: { valueAsNumber } }) => {
              const value = isNaN(valueAsNumber) ? null : valueAsNumber;
              updateEntry(entry.id, 'rate', value);
            }}
            size="small"
            error={entry.errors.rate}
          />
        </FormControl>
      </Box>
      <Box>

        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            required
            id={`margin-input-${entry.id}`}
            className={classes.textField}
            label="Margin (%)"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            value={entry.margin || ""}
            onChange={({ target: { valueAsNumber } }) => {
              const value = isNaN(valueAsNumber) ? null : valueAsNumber;
              updateEntry(entry.id, 'margin', value);
            }}
            size="small"
          />
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            required
            id={`total-input-${entry.id}`}
            className={classes.textField}
            label="Sub Total ($)"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            value={entry.total || ""}
            onChange={({ target: { valueAsNumber } }) => {
              const value = isNaN(valueAsNumber) ? null : valueAsNumber;
              updateEntry(entry.id, 'total', value);
            }}
            size="small"
            error={entry.errors.total}
          />
        </FormControl>
      </Box>
      <Box>
        <Typography>Must provide at least two of the following fields: Quantity, Rate and Sub-Total</Typography>
      </Box>

    </form>
  )
};

export default Entry;