import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tradeCell: {
        align: 'left',
        width: 140,
    },
    descriptionCell: {
        align: 'left',
        width: 500,
    },
    numberCell: {
        align: 'right',
        textAlign: 'right',
    },
}));

const defaultNoRate = {
    id: 0,
    trade: 'NO RATE',
    action: 'NOTE',
    item: 'NO COST/MULTIPLE ASPECTS',
    uom: 'ITEM',
    rate_dollars: 0,
};

const generateLabel = (comparisonRate) => {
    let base = `${comparisonRate.trade}`;

    const additional = [];

    if (comparisonRate.region_type) {
        additional.push(comparisonRate.region_type);
    }
    if (comparisonRate.state) {
        additional.push(comparisonRate.state);
    }
    if (comparisonRate.builder && comparisonRate.builder.name) {
        additional.push(comparisonRate.builder.name);
    }
    if (comparisonRate.company && comparisonRate.company.name) {
        additional.push(comparisonRate.company.name);
    }

    return `${base} (${additional.join(' / ')})`;
};

const SelectedRate = ({
    entry,
    analysedEntry,
    selectedComparisonRate,
    isSaved,
}) => {
    const classes = useStyles();

    const generateSavedLabel = useCallback(() => {
        if (isSaved) return 'Saved';
        return 'Unsaved';
    }, [isSaved]);

    const comparisonRate = selectedComparisonRate ?? defaultNoRate;
    return (
        <>
            {comparisonRate && (
                <>
                    <TableCell component="th" scope="row">
                        {comparisonRate.id}
                    </TableCell>
                    <TableCell className={classes.tradeCell}>
                        {generateLabel(comparisonRate)}
                    </TableCell>
                    <TableCell className={classes.tradeCell}>
                        {comparisonRate.contract_ref
                            ? comparisonRate.contract_ref
                            : 'N/A'}
                    </TableCell>
                    <TableCell className={classes.descriptionCell}>
                        {comparisonRate.action} - {comparisonRate.item}
                    </TableCell>
                    <TableCell className={classes.numberCell} align="right">
                        {comparisonRate.uom}
                    </TableCell>
                    <TableCell className={classes.numberCell} align="right">
                        {entry.quantity}
                    </TableCell>
                    <TableCell className={classes.numberCell} align="right">
                        {comparisonRate.rate_dollars}
                    </TableCell>
                    <TableCell className={classes.numberCell} align="right">
                        {analysedEntry ? analysedEntry.total_dollars : 'N/A'}
                    </TableCell>
                    <TableCell className={classes.numberCell} align="right">
                        {generateSavedLabel()}
                    </TableCell>
                </>
            )}
        </>
    );
};

export default SelectedRate;
