import React, { Fragment } from 'react';

import { FormGroup, makeStyles } from '@material-ui/core';

import Address from './Address';
import ClaimInfo from './ClaimInfo';
import QuoteInfo from './QuoteInfo';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        padding: theme.spacing(1),
        width: '80vw',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 200,
    },
    smallFormControl: {
        margin: theme.spacing(2),
        width: 140,
    },
    largeFormControl: {
        margin: theme.spacing(2),
        width: 300,
    },
    divider: {
        width: 200,
    },
}));

const QuoteDetails = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <FormGroup className={classes.formGroup}>
                <Address />
                <ClaimInfo />
                <QuoteInfo />
            </FormGroup>
        </Fragment>
    );
};

export default QuoteDetails;
