import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  roomSelect: {
    minWidth: 140,
    fontSize: 30,
  },
  measurement: {
    width: 100,
  },
  heading: {
    fontSize: '20px'
  },
  subHeading: {
    fontSize: '15px'
  }
}));

const createLocationSubHeading = (location) => {
  const {
    width,
    depth,
    height
  } = location;
  const measurements = [];
  if (depth) {
    measurements.push(`D: ${depth}mm`);
  };

  if (width) {
    measurements.push(`W: ${width}mm`);
  };

  if (height) {
    measurements.push(`H: ${height}mm`);
  };

  return measurements.join(" ");
}

const LocationHeader = ({ location }) => {
  const classes = useStyles();
  return (
    <Container>
      <Typography className={classes.heading}>{location.name}</Typography>
      <Typography className={classes.subHeading}>{createLocationSubHeading(location)}</Typography>
    </Container>
  );
};

export default LocationHeader;
