import { gql } from '@apollo/client';

const DOWNLOAD_MARKED_UP_QUOTE = gql`
  query DownloadMarkedUpQuote($id: Int!, $type: DownloadQuoteType) {
    quote_url: downloadQuote(id: $id, type: $type)
  }
`;

export {
    DOWNLOAD_MARKED_UP_QUOTE
}