import React, { useCallback, useState } from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import ReactS3Uploader from 'react-s3-uploader';
import { Button, Typography, FormGroup, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import OptionsSelect from '../../FormHelpers/OptionsSelect';
import { getLoggedInCompany } from '../../../utils';

const GET_QUOTE_OPTIONS = gql`
    query GetQuoteOptions {
        builders {
            value: id
            label: name
        }
    }
`;

const REQUEST_PRESIGN_URL = gql`
    mutation ($prepare_upload_input: PrepareUploadInput!) {
        prepareUpload(prepare_upload_input: $prepare_upload_input) {
            pre_signed_url
            file_name
            key
            bucket
        }
    }
`;

const SUBMIT_INBOUND_WEBSITE = gql`
    mutation ($inbound_website_input: InboundWebsiteInput!) {
        submitInboundWebsite(inbound_website_input: $inbound_website_input) {
            id
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    fragment: {
        padding: theme.spacing(1),
        width: '80vw',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    title: {
        marginTop: '15px',
    },
    childComponent: {
        marginTop: '20px',
        minWidth: '180px',
        width: '10vw',
    },
}));

const UploadNewQuote = () => {
    const classes = useStyles();
    const company = getLoggedInCompany();
    const companyOption = {
        value: company.id,
        label: company.name,
    };

    const [fileName, setFileName] = useState(null);
    const [bucket, setBucket] = useState(null);
    const [key, setKey] = useState(null);

    const [isUploaded, setIsUploaded] = useState(false);
    const [status, setStatus] = useState('');

    const [builderOptions, setBuilderOptions] = useState([]);
    const [postcode, setPostcode] = useState(null);

    const [selectedBuilderOption, setSelectedBuilderOption] = useState({});

    const onCompleteGetQuoteOptions = useCallback(
        (data) => {
            const { builders = [] } = data || {};
            setBuilderOptions(builders);
            setSelectedBuilderOption(builders[0] ?? {});
        },
        [setBuilderOptions, setSelectedBuilderOption]
    );

    const onBuilderOptionSelection = useCallback(
        ({ target: { value } }) => {
            setSelectedBuilderOption(value);
        },
        [setSelectedBuilderOption]
    );

    const onUpdatePostcodeValue = useCallback(
        ({ target: { value } }) => {
            setPostcode(value);
        },
        [setPostcode]
    );

    useQuery(GET_QUOTE_OPTIONS, {
        onCompleted: onCompleteGetQuoteOptions,
    });

    const [requestSignedUrl] = useMutation(REQUEST_PRESIGN_URL);
    const [submitInboundWebsite, { loading: submitLoading }] = useMutation(
        SUBMIT_INBOUND_WEBSITE
    );

    const processUpload = useCallback(
        (data, callback) => {
            const { prepareUpload } = data;
            const { pre_signed_url, bucket, key, file_name } =
                prepareUpload || {};
            const s3Data = {
                signedUrl: pre_signed_url,
            };
            setBucket(bucket);
            setKey(key);
            setFileName(file_name);
            callback(s3Data);
        },
        [setBucket, setKey, setFileName]
    );

    const handleGetSignedUrl = useCallback(
        async (file, callback) => {
            setFileName(file.name);
            const { data } = await requestSignedUrl({
                variables: {
                    prepare_upload_input: {
                        file_name: file.name,
                        content_type: file.type,
                        company_id: company.id,
                    },
                },
            });
            await processUpload(data, callback);
        },
        [processUpload, setFileName, requestSignedUrl, company.id]
    );

    const handleSucces = useCallback(
        (data) => {
            setIsUploaded(true);
        },
        [setIsUploaded]
    );

    const resetForm = useCallback(() => {
        setFileName(null);
        setBucket(null);
        setKey(null);
        setIsUploaded(false);
    }, [setFileName, setBucket, setKey, setIsUploaded]);
    const handleSubmit = useCallback(async () => {
        const { data } = await submitInboundWebsite({
            variables: {
                inbound_website_input: {
                    file_name: fileName,
                    bucket,
                    key,
                    company_id: company.id ?? null,
                    builder_id: selectedBuilderOption ?? null,
                    postcode,
                },
            },
        });
        if (data && data.submitInboundWebsite && data.submitInboundWebsite.id) {
            setStatus('success');
            resetForm();
        } else {
            setStatus('error');
        }
    }, [
        submitInboundWebsite,
        setStatus,
        resetForm,
        bucket,
        fileName,
        key,
        company,
        selectedBuilderOption,
        postcode,
    ]);

    return (
        <>
            <Typography className={classes.title}>Upload New Quote</Typography>
            <FormGroup className={classes.fragment}>
                <OptionsSelect
                    className={classes.childComponent}
                    label="Company"
                    options={[companyOption]}
                    selectedOption={companyOption}
                    disabled={true}
                />
                <OptionsSelect
                    className={classes.childComponent}
                    label="Builder"
                    options={builderOptions}
                    onChange={onBuilderOptionSelection}
                    selectedOption={selectedBuilderOption}
                    required={false}
                />
                <TextField
                    editing={true}
                    className={classes.childComponent}
                    tempValue={postcode}
                    keyName={'postcode'}
                    onChange={onUpdatePostcodeValue}
                    variant="outlined"
                    label="Postcode"
                    required={false}
                />
                <ReactS3Uploader
                    className={classes.childComponent}
                    getSignedUrl={handleGetSignedUrl}
                    onFinish={handleSucces}
                    uploadRequestHeaders={
                        {
                            // 'x-amz-acl': 'public-read', // set to this by default, we don't want to include this since we have a IAM bucket policy
                        }
                    }
                    contentDisposition="auto"
                />
                {isUploaded && (
                    <Button
                        onClick={handleSubmit}
                        disabled={submitLoading}
                        variant="outlined"
                        className={classes.childComponent}
                    >
                        Submit
                    </Button>
                )}
                {status === 'success' && (
                    <Typography>
                        Successfully uploaded. Ingestion is in progress. Go to
                        "Quotes" page to view quote.
                    </Typography>
                )}
                {status === 'error' && (
                    <Typography>
                        Something went wrong. No quote uploaded/processed
                    </Typography>
                )}
            </FormGroup>
        </>
    );
};

export default UploadNewQuote;
