import { toNumber } from 'lodash';
import { HighlightArea } from '@react-pdf-viewer/highlight';

const calculateTotalDollars = (rate: any, quantity: any) => {
    return rate * quantity;
};

const convertToCents = (input: string | number): number | null => {
    let cleanedString: string;

    // If the input is a string, remove the dollar sign and any commas
    if (typeof input === 'string') {
        cleanedString = input.replace(/\$|,/g, '');
    } else if (typeof input === 'number') {
        cleanedString = input.toString();
    } else {
        return null; // Return null for invalid input types
    }

    // Try parsing the cleaned string as a float
    const castValue = parseFloat(cleanedString);

    return isNaN(castValue) ? null : parseInt((castValue * 100).toFixed(), 10);
};

const convertToNumber = (value: any) => {
    return toNumber(value);
};

const convertHighlightAreasToSpan = (
    text: string,
    highlightAreas: HighlightArea[]
): {
    bbox: number[];
    text: string;
    page_number: number;
    bbox_type: string;
} | null => {
    const highlightArea = highlightAreas?.[0];

    if (!highlightArea) return null;

    const left = highlightArea.left / 100; // distance from left of page to left of bounding rectangle as ratio of page size
    const top = highlightArea.top / 100; // distance from top of page to top of bounding rectangle as ratio of page size
    const width = highlightArea.width / 100; // distance from left of bounding rectangle to right of bounding rectangle as ratio of page size
    const height = highlightArea.height / 100; // distance from top of bounding rectangle to bottom of bounding rectangle as ratio of page size

    return {
        bbox: [left, top, width, height],
        text: `${text}`,
        page_number: highlightArea.pageIndex,
        bbox_type: 'ratio',
    };
};

const getCompanyHostName = () => {
    const hostname = window.location.hostname;
    const hostnames = hostname.split('.');

    if (hostnames.length > 0) return hostnames[0];

    return hostnames;
};

const getLoggedInCompany = () => {
    const company = localStorage.getItem('company');

    if (!company) return {};

    return JSON.parse(company);
};

export {
    calculateTotalDollars,
    convertToCents,
    convertToNumber,
    convertHighlightAreasToSpan,
    getCompanyHostName,
    getLoggedInCompany,
};
