import { Fragment } from 'react';

const Highlight = ({ highlightAreas, highlightProps, key }: any) => {
    if (!highlightAreas) return <></>;
    return (
        <Fragment key={key}>
            {highlightAreas
                .filter(
                    (area: any) => area.pageIndex === highlightProps.pageIndex
                )
                .map((area: any, idx: number) => (
                    <div
                        key={idx}
                        style={Object.assign(
                            {},
                            {
                                background: 'yellow',
                                opacity: 0.4,
                            },
                            highlightProps.getCssProperties(
                                area,
                                highlightProps.rotation
                            )
                        )}
                    />
                ))}
        </Fragment>
    );
};

export default Highlight;
