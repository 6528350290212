import { gql } from '@apollo/client';

const GET_QUOTE = gql`
    query ($id: Int!) {
        quote(id: $id) {
            id
            quoted_by
            quoted_at
            quote_ref_id
            claim_number
            claim_type
            company_id
            company {
                name
            }
            builder {
                name
            }
            created_at
            address_component {
                unit_number
                street_number
                street
                suburb
                state
                postcode
            }
            format_id
            format {
                name
            }
            locations {
                id
                name
                total_dollars
                analysed_total_dollars
                width
                height
                depth
                entries {
                    id
                    entry_number
                    contract_ref
                    description
                    comment
                    uom
                    trade
                    trade_id
                    quantity
                    rate_dollars
                    total_dollars
                    analysed_entry {
                        id
                        total_dollars
                        rate_id
                        rate {
                            id
                            trade
                            item
                            action
                            contract_ref
                            uom
                            rate_dollars
                            state
                            region_type
                            company {
                                name
                            }
                            builder {
                                name
                            }
                        }
                        tolerance {
                            band
                            colour
                        }
                    }
                }
            }
        }
    }
`;

const GET_OPTIONS = gql`
    query getOptions($options: RateQueryOptions) {
        trades {
            value: id
            label: name
        }
        rates(options: $options) {
            id
            trade
            item
            contract_ref
            action
            uom
            is_active
            rate_dollars
            state
            region_type
            company_name
            builder_name
        }
        formats {
            value: id
            label: name
        }
        companies {
            value: id
            label: name
        }
    }
`;

const EDIT_QUOTE = gql`
    mutation EditQuote($quote_input: QuoteInput!) {
        editQuote(quote_input: $quote_input)
    }
`;

export { GET_QUOTE, GET_OPTIONS, EDIT_QUOTE };
