import React from 'react';
import enAu from 'dayjs/locale/en-au';
import DayJsUtils from '@date-io/dayjs';

import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';

const DateTimeInput = (props) => {
    const { selectedDate, handleDateChange, ...commProps } = props;
    return (
        <MuiPickersUtilsProvider locale={enAu} utils={DayJsUtils}>
            <DatePicker value={selectedDate} onChange={handleDateChange} {...commProps} />
        </MuiPickersUtilsProvider>
    );
};

export default DateTimeInput;