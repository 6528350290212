import { createContext } from 'react';

export const EditContext = createContext(false);
export const OptionsContext = createContext(null);

export const QuoteContext = createContext(null);
export const QuoteDispatchContext = createContext(null);

export const SavedQuoteContext = createContext(null);
export const SavedQuoteDispatchContext = createContext(null);
