import React from 'react';

import { TableCell, TextField } from '@material-ui/core';

const EditableCell = ({ editing, className, tempValue, keyName, updateValue, type = 'text', ...props }) => {
    if (editing) {
        return (
            <TableCell className={className}>
                <TextField
                    {...props}
                    autoFocus
                    className={className}
                    value={tempValue || ""}
                    onChange={({ target: { value, valueAsNumber } }) => {
                        let castValue = value;
                        if (type === "number") {
                            castValue = valueAsNumber;
                        }
                        updateValue(keyName, castValue);
                    }}
                    variant="outlined"
                    size="small"
                    type={type}
                />
            </TableCell>
        )
    }

    return (
        <TableCell className={className}>
            {tempValue}
        </TableCell>
    )
}

export default EditableCell;