import React, { useState, useCallback, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TableRow, TableCell } from '@material-ui/core';

import ComparisonRateOptions from '../ComparisonRate/ComparisonRateOptions';
import SelectedRate from '../ComparisonRate/SelectedRate';
import {
    OptionsContext,
    QuoteDispatchContext,
    SavedQuoteContext,
} from '../context';
import { QUOTE_ACTION } from '../constants';

const useStyles = makeStyles((theme) => ({
    typography: {
        margin: theme.spacing(2),
    },
    formControlSelect: {
        margin: theme.spacing(2),
        width: 180,
    },
    formControlNumber: {
        margin: theme.spacing(2),
        width: 100,
    },
    iconButton: {
        margin: theme.spacing(2),
    },
    root: {
        '& > *': {},
        backgroundColor: 'rgba(0, 60, 0, 0.1)',
    },
    tradeCell: {
        align: 'left',
        width: 140,
    },
    descriptionCell: {
        align: 'left',
        width: 500,
    },
    numberCell: {
        align: 'right',
        textAlign: 'right',
    },
}));

const EntryFurtherDetail = ({ entry, originalEntry, isOpen, handleSave }) => {
    const dispatch = useContext(QuoteDispatchContext);
    const savedQuote = useContext(SavedQuoteContext);
    const options = useContext(OptionsContext);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [analysedEntry, setAnalysedEntry] = useState(null);
    const [originalComparisonRate, setOriginalComparisonRate] = useState(null);
    const [selectedComparisonRate, setSelectedComparisonRate] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        const { analysed_entry } = entry || {};
        const { rate } = analysed_entry || {};

        setSelectedComparisonRate(rate);
        setAnalysedEntry(analysed_entry);

        const { analysed_entry: original_analysed_entry } = originalEntry || {};
        const { rate: original_rate } = original_analysed_entry || {};

        setOriginalComparisonRate(original_rate);
    }, [entry, originalEntry]);

    const updateEntryWithSelectedRate = useCallback(
        (selectedRate) => {
            dispatch({
                type: QUOTE_ACTION.UPDATE_RATE,
                payload: {
                    key: entry.id,
                    value: selectedRate,
                },
            });
        },
        [dispatch, entry]
    );

    const handleClickMoreOptions = useCallback(() => {
        setIsOptionsOpen((currentIsOptionsOpen) => !currentIsOptionsOpen);
    }, [setIsOptionsOpen]);

    const handleComparisonRateSelection = useCallback(
        (selectedComparisonRateId) => {
            const selectedRate = options.rates.find(
                (rate) => rate.id === selectedComparisonRateId
            );
            setSelectedComparisonRate(selectedRate);
            updateEntryWithSelectedRate(selectedRate);
        },
        [setSelectedComparisonRate, updateEntryWithSelectedRate, options.rates]
    );

    const handleClickReset = useCallback(() => {
        dispatch({
            type: QUOTE_ACTION.RESET_QUOTE,
            payload: savedQuote,
        });
    }, [dispatch, savedQuote]);

    const handleClickRemove = useCallback(() => {
        setSelectedComparisonRate(null);
        updateEntryWithSelectedRate(null);
    }, [setSelectedComparisonRate, updateEntryWithSelectedRate]);

    const handleClickSave = useCallback(() => {
        handleSave();
    }, [handleSave]);

    const isSaved = useCallback(() => {
        if (!analysedEntry) return true;

        if (analysedEntry.is_saved === undefined) return true;

        return !!analysedEntry.is_saved;
    }, [analysedEntry]);

    if (!isOpen) return null;

    return (
        <>
            <TableRow className={classes.root}>
                <SelectedRate
                    entry={entry}
                    analysedEntry={analysedEntry}
                    selectedComparisonRate={selectedComparisonRate}
                    originalComparisonRate={originalComparisonRate}
                    isSaved={isSaved()}
                />
            </TableRow>
            <TableRow className={classes.root}>
                <TableCell colSpan={9}>
                    <Button onClick={handleClickMoreOptions}>Options</Button>
                    <Button onClick={handleClickReset}>Reset</Button>
                    <Button onClick={handleClickRemove}>No Rate</Button>
                    {!isSaved() && (
                        <Button onClick={handleClickSave}>Save</Button>
                    )}
                    {isOptionsOpen && (
                        <ComparisonRateOptions
                            selectedComparisonRate={selectedComparisonRate}
                            onRateSelection={handleComparisonRateSelection}
                        />
                    )}
                </TableCell>
            </TableRow>
        </>
    );
};

export default EntryFurtherDetail;
