import { useHistory, withRouter } from 'react-router-dom';

import {
    TableRow,
    TableCell,
    TableBody,
    Button,
    Typography,
} from '@material-ui/core';
import { AssignmentIndOutlined } from '@material-ui/icons';

import { formatDate } from '../../utils/format';

const Row = ({ quotes, loading }) => {
    const { push } = useHistory();

    const handleViewQuote = (quoteId) => {
        push(`/quote/${quoteId}`);
    };

    if (loading) {
        return <Typography>Loading quotes...</Typography>;
    }

    if (!quotes || !quotes.length) {
        return <Typography>No quotes found.</Typography>;
    }

    return (
        <TableBody>
            {quotes.map((quote) => {
                return (
                    <TableRow>
                        <TableCell>{quote.id}</TableCell>
                        <TableCell>
                            {quote.file ? quote.file.name : ''}
                        </TableCell>
                        <TableCell>
                            {quote.company ? quote.company.name : ''}
                        </TableCell>
                        <TableCell>{formatDate(quote.created_at)}</TableCell>
                        <TableCell>
                            <Button
                                variant="contained"
                                disableElevation={true}
                                startIcon={<AssignmentIndOutlined />}
                                onClick={() => handleViewQuote(quote.id)}
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

export default withRouter(Row);
