import React, { useState, Fragment, useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TableRow, TableCell } from '@material-ui/core';
import EditableCell from '../../FormHelpers/EditableCell';
import { KeyboardArrowUp, KeyboardArrowDown, Delete } from '@material-ui/icons';

import EntryFurtherDetail from './EntryFurtherDetailv2';
import { EditContext, QuoteDispatchContext } from '../context';
import { QUOTE_ACTION } from '../constants';

const useStyles = makeStyles((theme) => ({
    typography: {
        margin: theme.spacing(2),
    },
    formControlSelect: {
        margin: theme.spacing(2),
        width: 180,
    },
    formControlNumber: {
        margin: theme.spacing(2),
        width: 100,
    },
    iconButton: {
        margin: theme.spacing(2),
    },
    root: {
        '& > *': {},
    },
    tableCell: {
        display: 'table-cell',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    multiCell: {
        display: 'inline-flex',
        flexDirection: 'column',
        align: 'left',
        width: 400,
        borderBottom: '0',
    },
    tradeCell: {
        align: 'left',
        width: 140,
    },
    contractRefCell: {
        align: 'left',
        width: 100,
    },
    descriptionCell: {
        align: 'left',
        width: 400,
        borderBottom: 0,
    },
    numberCell: {
        align: 'right',
        textAlign: 'right',
        width: 80,
    },
    colourCell: {
        align: 'right',
        textAlign: 'right',
        backgroundColor: ({ toleranceColour }) => toleranceColour,
    },
}));

const EntryRow = ({ entry, originalEntry, handleSave }) => {
    const dispatch = useContext(QuoteDispatchContext);
    const edit = useContext(EditContext);
    const [isOpen, setIsOpen] = useState(false);

    const { analysed_entry } = entry || {};
    const { total_dollars: analysed_entry_total_dollars = 0, tolerance = {} } =
        analysed_entry || {};

    const classes = useStyles({ toleranceColour: tolerance.colour });

    const updateDetailsWrapper = useCallback(
        (keyName, value) => {
            dispatch({
                type: QUOTE_ACTION.UPDATE_ENTRY,
                payload: {
                    key: keyName,
                    value,
                    entryId: entry.id,
                },
            });
        },
        [entry, dispatch]
    );

    const removeEntryWrapper = useCallback(() => {
        dispatch({
            type: QUOTE_ACTION.REMOVE_ENTRY,
            payload: {
                entryId: entry.id,
            },
        });
    }, [entry, dispatch]);

    return (
        <Fragment>
            <TableRow className={classes.root}>
                {edit ? (
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => removeEntryWrapper()}
                        >
                            <Delete />
                        </IconButton>
                    </TableCell>
                ) : (
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {isOpen ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </TableCell>
                )}
                <EditableCell
                    editing={edit}
                    className={classes.tradeCell}
                    tempValue={entry.trade}
                    keyName={'trade'}
                    updateValue={updateDetailsWrapper}
                />
                <EditableCell
                    // currently backend doesn't support updating this. Needs dev work
                    // editing={edit}
                    editing={false}
                    className={classes.contractRefCell}
                    tempValue={entry.contract_ref}
                    keyName={'contract_ref'}
                    updateValue={updateDetailsWrapper}
                />
                <TableCell className={classes.tableCell}>
                    <TableCell className={classes.multiCell} rows={3}>
                        <EditableCell
                            editing={edit}
                            className={classes.descriptionCell}
                            tempValue={entry.description}
                            keyName={'description'}
                            updateValue={updateDetailsWrapper}
                            multiline={true}
                        />
                        {(entry.comment || edit) && (
                            <EditableCell
                                editing={edit}
                                className={classes.descriptionCell}
                                tempValue={entry.comment}
                                keyName={'comment'}
                                updateValue={updateDetailsWrapper}
                                multiline={true}
                            />
                        )}
                    </TableCell>
                </TableCell>
                <EditableCell
                    editing={edit}
                    className={classes.numberCell}
                    tempValue={entry.uom}
                    keyName={'uom'}
                    updateValue={updateDetailsWrapper}
                />
                <EditableCell
                    editing={edit}
                    className={classes.numberCell}
                    tempValue={entry.quantity}
                    keyName={'quantity'}
                    type={'number'}
                    updateValue={updateDetailsWrapper}
                />
                <EditableCell
                    editing={edit}
                    className={classes.numberCell}
                    tempValue={entry.rate_dollars}
                    keyName={'rate_dollars'}
                    type={'number'}
                    updateValue={updateDetailsWrapper}
                />
                <TableCell className={classes.numberCell}>
                    {entry.total_dollars}
                </TableCell>
                <TableCell className={classes.colourCell} align="right">
                    {analysed_entry_total_dollars}
                </TableCell>
            </TableRow>
            <EntryFurtherDetail
                className={classes.root}
                entry={entry}
                originalEntry={originalEntry}
                isOpen={isOpen}
                handleSave={handleSave}
            />
        </Fragment>
    );
};

const entryPropsAreEqual = (prevEntry, newEntry) => {
    return prevEntry.entry === newEntry.entry;
};

export default React.memo(EntryRow, entryPropsAreEqual);
