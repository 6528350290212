import React from 'react';

import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    smallCell: {
        align: "left",
        width: "5vw"
    },
    mediumCell: {
        align: "left",
        width: "15vw"
    }
}))

const Header = () => {
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.smallCell}>ID</TableCell>
                <TableCell className={classes.mediumCell}>Upload Source</TableCell>
                <TableCell className={classes.mediumCell}>Status</TableCell>
                <TableCell className={classes.mediumCell}>Date</TableCell>
                <TableCell className={classes.smallCell}>View</TableCell>
            </TableRow>
        </TableHead>
    )
};

export default Header;