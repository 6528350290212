import React, { Fragment, useState } from 'react';

import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

import Batch from './Batch';
import Job from './Job';

import { formatDate } from '../../utils/format';

const Row = ({ batch }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Fragment>
            <TableRow>
                <TableCell>{batch.id}</TableCell>
                <TableCell>{batch.trigger}</TableCell>
                <TableCell>{batch.status_details.label}</TableCell>
                <TableCell>{formatDate(batch.created_at)}</TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <Batch batch={batch} isOpen={isOpen}/>
            <Job jobs={batch.jobs} isOpen={isOpen} />
        </Fragment>
    )
};

export default Row;