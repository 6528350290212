import { useContext } from 'react';
import { QuoteContext } from '../context';
import { RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import HighlightEntry from './HighlightEntry';

const HighlightEntries = ({
    highlightProps,
}: {
    highlightProps: RenderHighlightsProps;
}) => {
    const quote: any = useContext(QuoteContext);

    if (!quote) return;

    return quote.locations.map((location: any) => {
        return location.entries.map((entry: any) => {
            return (
                <HighlightEntry
                    highlightProps={highlightProps}
                    entry={entry}
                    key={`highlight-key-${entry.id}`}
                />
            );
        });
    });
};

export default HighlightEntries;
// export default memo(Highlight);
