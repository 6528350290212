import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';

import Header from './Header';
import Row from './Row';

const useStyles = makeStyles((theme) => ({
    row: {
        backgroundColor: "rgba(0, 60, 0, 0.1)"
    }
}));

const Job = ({jobs, isOpen}) => {
    const classes = useStyles();

    if (!isOpen) return null;

    return (
        <TableRow className={classes.row}>
            <TableCell colSpan={1}>Jobs</TableCell>
            <TableCell colSpan={7}>
                <Header />
                {jobs.map((job, index) => (
                    <Row job={job} jobIndex={index}/>
                ))}
            </TableCell>
        </TableRow>
    );
};

export default Job;