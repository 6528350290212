import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function CheckboxWithLabel(props) {
    return (
        <FormControl component="fieldset">
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox {...props} />}
                    label={props.label}
                />
            </FormGroup>
        </FormControl>
    );
}
