import React, { useState, useCallback, Fragment } from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import { Container, Typography } from '@material-ui/core';

import RateBases from '../../components/RateBases';
import AddRate from '../../components/Forms/AddRateForm';

const GET_RATE_BASES = gql`
    query {
        rate_bases {
            id
            trade
            item
            action
            uom
            current_applied_rate {
                id
                trade
                item
                action
                uom
                rate_dollars
                is_active
                created_at
            }

            rates {
                id
                trade
                item
                action
                uom
                rate_dollars
                is_active
                created_at
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const Rates = () => {
    const [rateBases, setRateBases] = useState([]);
    const classes = useStyles();

    const onCompletedGetRates = useCallback(
        (data) => {
            if (data) {
                const { rate_bases = [] } = data;
                setRateBases(rate_bases);
            }
        },
        [setRateBases]
    );

    const { loading, refetch } = useQuery(GET_RATE_BASES, {
        onCompleted: onCompletedGetRates,
        notifyOnNetworkStatusChange: true,
    });

    return (
        <Fragment>
            <Container className={classes.container}>
                {loading && <Typography>Loading...</Typography>}
                <Typography>Rates</Typography>
                <AddRate refetchRates={refetch} />
                <RateBases
                    rateBases={rateBases}
                    refetchRates={refetch}
                    loadingRates={loading}
                />
            </Container>
        </Fragment>
    );
};

export default Rates;
