import { Button, Container, Typography, makeStyles } from '@material-ui/core';
import { useCallback, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { EDIT_ENTRIES } from '../gql';
import { QuoteContext, QuoteDispatchContext } from '../context';
import { generateEditEntriesVariables } from '../utils';
import { QUOTE_ACTION } from '../constants';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    container: {
        padding: '10px',
    },
    error: {
        color: 'red',
    },
}));

const ButtonContainer = ({ refetch, savedQuote }: any) => {
    const classes = useStyles();
    const { push } = useHistory();
    const [error, setError] = useState('');
    const quote: any = useContext(QuoteContext);
    const dispatch: any = useContext(QuoteDispatchContext);

    const [editEntries, { loading: saving }] = useMutation(EDIT_ENTRIES, {
        onCompleted: () => {
            refetch();
        },
        onError: () => {
            setError(
                'Failed to save, please contact developer and refresh the page'
            );
        },
    });

    const handleSaveClick = useCallback(() => {
        if (!quote) return;
        editEntries({
            variables: {
                quote_id: quote.id,
                entries_input: generateEditEntriesVariables(quote),
            },
        });
    }, [editEntries, quote]);

    const handleReturnToQuoteClick = useCallback(() => {
        if (!quote) return;
        push(`/quote/${quote.id}`);
    }, [push, quote]);

    const handleResetClick = useCallback(() => {
        if (!savedQuote) return;
        dispatch({
            type: QUOTE_ACTION.SET_QUOTE,
            payload: savedQuote,
        });
    }, [dispatch, savedQuote]);

    return (
        <Container className={classes.container}>
            <Button onClick={handleReturnToQuoteClick}>RETURN TO QUOTE</Button>
            <Button onClick={handleSaveClick}>SAVE</Button>
            <Button onClick={handleResetClick}>RESET</Button>
            {saving && <Typography>saving...</Typography>}
            {error && (
                <Typography className={classes.error}>{error}</Typography>
            )}
        </Container>
    );
};

export default ButtonContainer;
