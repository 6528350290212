import React, { useContext } from 'react';

import Location from './Location';
import { QuoteContext } from '../context';

const Locations = () => {
    const quote: any = useContext(QuoteContext);

    if (!quote) return;

    return quote.locations.map((location: any) => {
        return (
            <Location location={location} key={`location-key-${location.id}`} />
        );
    });
};

export default Locations;
