import React from 'react';

import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    box: {
        minWidth: '180px',
        marginTop: '10px',
    },
}));

const OptionsSelect = ({
    onChange,
    options,
    selectedOption,
    label,
    disabled,
}) => {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-label" variant="outlined">
                    {label}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedOption.value}
                    label={label}
                    onChange={onChange}
                    autoWidth
                    disabled={disabled}
                >
                    {options.map((option) => {
                        return (
                            <MenuItem value={option.value}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default OptionsSelect;
