import React from 'react';
import short from 'short-uuid';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton, Container, Button, Box, Typography } from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons';


import RoomHeader from './Header'
import Entry from '../Entry';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: '25px',
    border: '2px solid rgba(0,0,0,0.2)',
    margin: '10px'
  },
  button: {
    margin: theme.spacing(2)
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  titleElement: {
    margin: '5px',
    padding: '5px'
  }
}));

const defaultEntry = {
  entry_number: 0,
  trade_id: null,
  trade: null,
  uom_id: null,
  uom: null,
  quantity: null,
  rate: null,
  total: null,
  errors: {
    uom: false,
    trade: false,
    quantity: false,
    rate: false,
    total: false
  }
}

const Room = ({ room, removeRoom, updateRoom, tradeOptions, uomOptions }) => {
  const classes = useStyles();

  const _handleAddEntry = () => {
    const newEntry = {
      ...defaultEntry,
      id: short.generate(),
      entry_number: room.entries.length,
    };
    const updatedEntries = [...room.entries, newEntry];

    updateRoom(room.id, 'entries', updatedEntries);
  };

  const _handleRemoveEntry = (entryId) => {
    const filteredEntries = room.entries.filter(entry => {
      if (entry.id === entryId) return false;
      return true;
    });

    let count = 0;
    const updatedEntries = filteredEntries.map(entry => {
      const updatedEntry = {
        ...entry,
        entry_number: count,
      };
      count++
      return updatedEntry;
    });

    updateRoom(room.id, 'entries', updatedEntries);
  };

  const _handleUpdateEntry = (entryId, key, value) => {
    const updatedEntries = room.entries.map(entry => {
      if (entry.id !== entryId) return entry;

      return {
        ...entry,
        [key]: value,
      }
    });

    updateRoom(room.id, 'entries', updatedEntries);
  }

  return (
    <Container className={classes.container}>
      <Box className={classes.titleBox}>
        <Typography className={classes.titleElement}>Room #{room.location_number + 1}</Typography>
        <IconButton
          className={classes.titleElement}
          variant="contained"
          disableElevation={true}
          color="primary"
          onClick={() => removeRoom(room.id)}
        >
          <Delete />
        </IconButton>

      </Box>
      <RoomHeader
        room={room}
        removeRoom={removeRoom}
        updateRoom={updateRoom}
      />
      <Container>
        {
          room.entries.map(entry => {
            return (
              <Entry
                entry={entry}
                removeEntry={_handleRemoveEntry}
                updateEntry={_handleUpdateEntry}
                tradeOptions={tradeOptions}
                uomOptions={uomOptions}
              />
            )
          })
        }
      </Container>
      <Button
        variant="contained"
        disableElevation={true}
        className={classes.button}
        startIcon={<Add />}
        onClick={_handleAddEntry}
      >
        Entry
      </Button>
    </Container>
  )
};

export default Room;
