import React, { useContext, useState } from 'react';

import {
    highlightPlugin,
    MessageIcon,
    RenderHighlightContentProps,
    RenderHighlightsProps,
    RenderHighlightTargetProps,
} from '@react-pdf-viewer/highlight';
import {
    Button,
    DocumentLoadEvent,
    Position,
    Tooltip,
    Viewer,
    Worker,
} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { QuoteContext, QuoteDispatchContext } from '../context';
import { QUOTE_ACTION } from '../constants';
import { Container, Typography } from '@material-ui/core';
import Locations from '../Location/Locations';
import HighlightEntries from './HighlightEntries';

const ViewerComponent = () => {
    const [url, setUrl] = useState('');
    const quote: any = useContext(QuoteContext);
    const dispatch: any = useContext(QuoteDispatchContext);

    const handleLoadSuccess = async (doc: DocumentLoadEvent) => {
        // Not being used at the moment, here for reference
        try {
            const firstPage = await doc.doc.getPage(1);
            firstPage.getViewport({ scale: 1 });
        } catch {
            return;
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files ?? [];
        files.length > 0 &&
            setUrl(URL.createObjectURL(files[0] as Blob | MediaSource));
    };

    const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
        <div
            style={{
                background: '#eee',
                display: 'flex',
                position: 'absolute',
                left: `${props.selectionRegion.left}%`,
                top: `${
                    props.selectionRegion.top + props.selectionRegion.height
                }%`,
                transform: 'translate(0, 8px)',
                zIndex: 1,
            }}
        >
            <Tooltip
                position={Position.TopCenter}
                target={
                    <Button onClick={props.toggle}>
                        <MessageIcon />
                    </Button>
                }
                content={() => <div style={{ width: '100px' }}>Add</div>}
                offset={{ left: 0, top: -8 }}
            />
        </div>
    );

    const renderHighlightContent = (props: RenderHighlightContentProps) => {
        const addField = (
            field:
                | 'trade'
                | 'description'
                | 'quantity'
                | 'rate_dollars'
                | 'uom'
                | 'total_dollars',
            highlightField: string
        ) => {
            dispatch({
                type: QUOTE_ACTION.UPDATE_ENTRY_AFTER_HIGHLIGHT,
                payload: {
                    key: field,
                    value: props.selectedText,
                    highlightKey: highlightField,
                    highlightAreas: props.highlightAreas,
                },
            });
            props.cancel();
        };

        return (
            <div
                style={{
                    background: '#fff',
                    border: '1px solid rgba(0, 0, 0, .3)',
                    borderRadius: '2px',
                    padding: '8px',
                    position: 'absolute',
                    left: `${props.selectionRegion.left}%`,
                    top: `${
                        props.selectionRegion.top + props.selectionRegion.height
                    }%`,
                    zIndex: 1,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        marginTop: '8px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '2px',
                        }}
                    >
                        <Button
                            onClick={() =>
                                addField('trade', 'trade_highlight_areas')
                            }
                        >
                            Trade
                        </Button>
                        <Button
                            onClick={() =>
                                addField(
                                    'description',
                                    'description_highlight_areas'
                                )
                            }
                        >
                            Description
                        </Button>
                        <Button
                            onClick={() =>
                                addField('quantity', 'quantity_highlight_areas')
                            }
                        >
                            Quantity
                        </Button>
                        <Button
                            onClick={() =>
                                addField('uom', 'uom_highlight_areas')
                            }
                        >
                            UOM
                        </Button>
                        <Button
                            onClick={() =>
                                addField('rate_dollars', 'rate_highlight_areas')
                            }
                        >
                            Rate
                        </Button>
                        <Button
                            onClick={() =>
                                addField(
                                    'total_dollars',
                                    'total_highlight_areas'
                                )
                            }
                        >
                            Total
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const renderHighlights = (props: RenderHighlightsProps) => {
        return <HighlightEntries highlightProps={props} />;
    };
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget,
        renderHighlightContent,
        renderHighlights,
    });

    if (!quote) {
        return (
            <Container>
                <Typography>Loading...</Typography>
            </Container>
        );
    }

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <input type="file" accept=".pdf" onChange={onChange} />
            <div
                style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    height: '600px',
                    overflow: 'scroll',
                }}
            >
                <div
                    style={{
                        borderRight: '1px solid rgba(0, 0, 0, 0.3)',
                        width: '40%',
                        overflow: 'scroll',
                        height: '600px',
                    }}
                >
                    <Locations />
                </div>
                {url ? (
                    <div
                        style={{
                            flex: '1 1 0',
                            overflow: 'auto',
                        }}
                    >
                        <Viewer
                            fileUrl={url}
                            plugins={[
                                defaultLayoutPluginInstance,
                                highlightPluginInstance,
                            ]}
                            onDocumentLoad={handleLoadSuccess}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            alignItems: 'center',
                            border: '2px dashed rgba(0, 0, 0, .3)',
                            display: 'flex',
                            fontSize: '2rem',
                            height: '100%',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        Preview area
                    </div>
                )}
            </div>
        </Worker>
    );
};

export default ViewerComponent;
