import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableRow,
  TableCell,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  typography: {
    margin: theme.spacing(2),
  },
  formControlSelect: {
    margin: theme.spacing(2),
    width: 180,
  },
  formControlNumber: {
    margin: theme.spacing(2),
    width: 100,
  },
  iconButton: {
    margin: theme.spacing(2),
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  tradeCell: {
    align: "left",
    width: 140,
  },
  descriptionCell: {
    align: "left",
    width: 500,
  },
  numberCell: {
    align: "right",
    textAlign: "right",
  },
}));

const LocationSummary = ({ location }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.root}>
        <TableCell />
        <TableCell className={classes.tradeCell} />
        <TableCell className={classes.descriptionCell} />
        <TableCell className={classes.numberCell} />
        <TableCell className={classes.numberCell}>Total ($)</TableCell>
        <TableCell className={classes.numberCell} />
        <TableCell className={classes.numberCell}>{location.total_dollars}</TableCell>
        <TableCell className={classes.numberCell}>{location.analysed_total_dollars}</TableCell>
    </TableRow>
  );
};

export default LocationSummary;
