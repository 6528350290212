import { HighlightArea } from '@react-pdf-viewer/highlight';

const updateEntry = ({
    quote,
    entryId,
    key,
    value,
}: {
    quote: any;
    entryId: any;
    key: string;
    value: any;
}) => {
    const updatedLocationsEntry = quote.locations.map((location: any) => {
        const entries = location.entries.map((entry: any) => {
            if (entry.id === entryId) {
                const updatedEntry = {
                    ...entry,
                    [key]: value,
                };
                return updatedEntry;
            }
            // want to reset this field for all entries
            if (key === 'isSelected' && value === true) {
                const updatedEntry = {
                    ...entry,
                    isSelected: false,
                };
                return updatedEntry;
            }

            return entry;
        });

        return {
            ...location,
            entries,
        };
    });
    const updatedQuote = {
        ...quote,
        locations: updatedLocationsEntry,
    };

    return updatedQuote;
};

const updateEntryAfterHighlight = ({
    quote,
    key,
    value,
    highlightKey,
    highlightAreas,
}: {
    quote: any;
    key: string;
    value: any;
    highlightKey: string;
    highlightAreas: HighlightArea;
}) => {
    let selectedEntry: any = null;
    quote.locations.forEach((location: any) => {
        location.entries.forEach((entry: any) => {
            if (entry.isSelected) {
                selectedEntry = entry;
                return;
            }
        });
    });

    if (!selectedEntry) {
        return quote;
    }

    const updatedLocationsEntry = quote.locations.map((location: any) => {
        const entries = location.entries.map((entry: any) => {
            if (entry.id === selectedEntry.id) {
                const updatedEntry = {
                    ...entry,
                    [key]: value,
                    [highlightKey]: highlightAreas,
                };
                return updatedEntry;
            }

            return entry;
        });

        return {
            ...location,
            entries,
        };
    });

    const updatedQuote = {
        ...quote,
        locations: updatedLocationsEntry,
    };

    return updatedQuote;
};

export { updateEntry, updateEntryAfterHighlight };
