import { QUOTE_ACTION } from './constants';
import { updateEntry, updateEntryAfterHighlight } from './reducerActions';

export const quoteReducer = (
    quote: any,
    action: {
        type: string;
        payload: any;
    }
) => {
    switch (action.type) {
        case QUOTE_ACTION.SET_QUOTE:
            return action.payload;
        case QUOTE_ACTION.UPDATE_ENTRY:
            return updateEntry({
                quote,
                entryId: action.payload.entryId,
                key: action.payload.key,
                value: action.payload.value,
            });
        case QUOTE_ACTION.UPDATE_ENTRY_AFTER_HIGHLIGHT:
            return updateEntryAfterHighlight({
                quote,
                key: action.payload.key,
                value: action.payload.value,
                highlightKey: action.payload.highlightKey,
                highlightAreas: action.payload.highlightAreas,
            });
        default:
            return quote;
    }
};
