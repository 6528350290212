import React from 'react';
import { Select as BaseSelect, MenuItem } from '@material-ui/core';

const Select = ({ options, ...props }) => {
    return (
        <BaseSelect {...props}>
            {options.map((option, index) => (
                <MenuItem
                    id={`menu-item-${index}-${option.value}`}
                    key={`menu-item-${index}-${option.value}`}
                    value={option.value}
                >
                    {option.label}
                </MenuItem>
            ))}
        </BaseSelect>
    );
};

export default Select;
