import { makeStyles } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  tradeCell: {
    align: "left",
    width: 140,
  },
  descriptionCell: {
    align: "left",
    width: 500,
  },
  numberCell: {
    align: "right",
    textAlign: "right",
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell className={classes.tradeCell}>Trade</TableCell>
        <TableCell className={classes.descriptionCell}>Item</TableCell>
        <TableCell className={classes.tradeCell}>Action</TableCell>
        <TableCell className={classes.numberCell}>UOM</TableCell>
        <TableCell className={classes.numberCell}>Rate ($)</TableCell>
        <TableCell className={classes.numberCell}>Active</TableCell>
        <TableCell className={classes.numberCell}>Created At</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default Header;
