import React, { Fragment, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Button, Typography } from '@material-ui/core';

import { DOWNLOAD_MARKED_UP_QUOTE } from './gql';

const Download = ({ quoteId }) => {
    const onCompletedDownloadMarkedUpQuote = useCallback((data) => {
        if (data && data.quote_url) {
            return window.open(data.quote_url, '_blank');
        }
    }, []);

    const [downloadMarkedUpQuoteQuery, { loading: downloadQuoteLoading }] =
        useLazyQuery(DOWNLOAD_MARKED_UP_QUOTE, {
            fetchPolicy: 'no-cache',
            onCompleted: onCompletedDownloadMarkedUpQuote,
        });

    const handleDownloadMarkedUpQuote = useCallback(() => {
        downloadMarkedUpQuoteQuery({
            variables: {
                id: parseInt(quoteId),
                type: 'ANALYSED',
            },
        });
    }, [downloadMarkedUpQuoteQuery, quoteId]);

    const handleDownloadOriginalQuote = useCallback(() => {
        downloadMarkedUpQuoteQuery({
            variables: {
                id: parseInt(quoteId),
                type: 'ORIGINAL',
            },
        });
    }, [downloadMarkedUpQuoteQuery, quoteId]);

    return (
        <Fragment>
            <Button onClick={handleDownloadMarkedUpQuote}>
                Download Marked PDF
            </Button>
            <Button onClick={handleDownloadOriginalQuote}>
                Download Original PDF
            </Button>
            {downloadQuoteLoading && (
                <Typography>Preparing Download...</Typography>
            )}
        </Fragment>
    );
};

export default Download;
