import {
    convertHighlightAreasToSpan,
    convertToCents,
    convertToNumber,
} from '../../utils';

const generateEditEntriesVariables = (quote: any) => {
    const entriesInput: any[] = [];
    quote.locations.forEach((location: any) => {
        const entries = generateUpdateEntryDetails(location.entries);
        entriesInput.push(...entries);
    });

    return entriesInput;
};

const generateUpdateEntryDetails = (entries: any) => {
    return entries.map((entry: any) => {
        const entryDetails = {
            id: entry.id,
            page_number: extractPageNumberFromHighlightAreas(entry),
            trade: entry.trade,
            trade_span: convertHighlightAreasToSpan(
                entry.trade,
                entry.trade_highlight_areas
            ),
            description: entry.description,
            description_span: convertHighlightAreasToSpan(
                entry.description,
                entry.description_highlight_areas
            ),
            comment: entry.comment,
            uom: entry.uom,
            uom_span: convertHighlightAreasToSpan(
                entry.uom,
                entry.uom_highlight_areas
            ),
            quantity: convertToNumber(entry.quantity),
            quantity_span: convertHighlightAreasToSpan(
                entry.quantity,
                entry.quantity_highlight_areas
            ),
            rate_cents: convertToCents(entry.rate_dollars),
            rate_span: convertHighlightAreasToSpan(
                entry.rate_dollars,
                entry.rate_highlight_areas
            ),
            total_cents: convertToCents(entry.total_dollars),
            total_span: convertHighlightAreasToSpan(
                entry.total_dollars,
                entry.total_highlight_areas
            ),
        };

        return entryDetails;
    });
};

const extractPageNumberFromHighlightAreas = (entry: any) => {
    const rateHighlightArea = entry.rate_highlight_areas?.[0];
    if (!!rateHighlightArea) {
        return rateHighlightArea.pageIndex;
    }
    const totalHighlightArea = entry.total_highlight_areas?.[0];
    if (!!totalHighlightArea) {
        return totalHighlightArea.pageIndex;
    }
    return undefined;
};

export { generateEditEntriesVariables };
