import React, { useCallback } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { AppBar, Box, Toolbar, makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const AppBarWrapper = () => {
    const classes = useStyles();
    const { push } = useHistory();
    const handleMenuClick = useCallback(
        (link) => {
            push(link);
        },
        [push]
    );

    const handleLogout = useCallback(() => {
        localStorage.removeItem('api_token');
        localStorage.removeItem('company');
        push('/login');
    }, [push]);

    return (
        <AppBar position="static">
            <Toolbar className={classes.toolbar}>
                <Box>
                    <Button
                        color="inherit"
                        onClick={() => handleMenuClick('/')}
                    >
                        QUOTES
                    </Button>
                    <Button
                        color="inherit"
                        onClick={() => handleMenuClick('/quote/upload')}
                    >
                        UPLOAD
                    </Button>
                    {/* No longer supporting manual upload - 2024-02-02 */}
                    {/* <Button
                        color="inherit"
                        onClick={() => handleMenuClick('/quote/upload-manual')}
                    >
                        MANUAL UPLOAD
                    </Button> */}
                    <Button
                        color="inherit"
                        onClick={() => handleMenuClick('/quote/upload-log')}
                    >
                        UPLOAD LOG
                    </Button>
                    <Button
                        color="inherit"
                        onClick={() => handleMenuClick('/rates')}
                    >
                        RATES
                    </Button>
                </Box>
                <Button color="inherit" onClick={() => handleLogout()}>
                    LOG OUT
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default withRouter(AppBarWrapper);
