import React, { Fragment, useCallback, useContext } from 'react';

import {
    Typography,
    InputLabel,
    makeStyles,
    Box,
    FormControl,
    Divider,
} from '@material-ui/core';
import Select from '../../../FormHelpers/Select';
import {
    EditContext,
    OptionsContext,
    QuoteContext,
    QuoteDispatchContext,
} from '../../../Quote/context';
import { QUOTE_ACTION } from '../../../Quote/constants';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 200,
    },
    smallFormControl: {
        margin: theme.spacing(2),
        width: 140,
    },
    largeFormControl: {
        margin: theme.spacing(2),
        width: 300,
    },
    divider: {
        width: 200,
    },
}));

const QuoteInfo = () => {
    const edit = useContext(EditContext);
    const quote = useContext(QuoteContext);
    const dispatch = useContext(QuoteDispatchContext);
    const options = useContext(OptionsContext);

    const classes = useStyles();

    const updateInfo = useCallback(
        (key, value) => {
            dispatch({
                type: QUOTE_ACTION.UPDATE_INFO,
                payload: {
                    key,
                    value,
                },
            });
        },
        [dispatch]
    );

    return (
        <Fragment>
            <Typography>Quote Information</Typography>
            <Divider className={classes.divider} />
            <Box>
                <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                >
                    <InputLabel>Company</InputLabel>
                    <Select
                        label="Company"
                        id={`company-input`}
                        options={options.companies}
                        value={quote.company_id || ''}
                        onChange={({ target: { value } }) => {
                            updateInfo('company_id', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
            </Box>
        </Fragment>
    );
};

export default QuoteInfo;
