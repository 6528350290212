import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink
} from "@apollo/client";

import config from '../config';

let client = null;
const uri = config.api;
const cache = new InMemoryCache()


const resetLogin = () => {
  localStorage.removeItem('api_token');
  window.location.href = `/login`;
  return {};
}

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('api_token');
  operation.setContext(({ headers }) => ({
    headers: { ...headers, 'x-api-token': token }
  }));
  return forward(operation);
})

const middleware401Link = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    let errorMessage = null;
    try {
      errorMessage = response.errors[0].message;
    } catch {
      errorMessage = '';
    }

    if (errorMessage && errorMessage.includes('Unable to authenticate')) {
      return resetLogin();
    }

    return response;
  })
})

const httpLink = new HttpLink({ uri });

const link = ApolloLink.from([
  middlewareAuthLink,
  middleware401Link,
  httpLink
]);

const init = () => {
  if (client) return client;
  client = new ApolloClient({
    cache,
    link,
  })
  return client;
};

export default init();