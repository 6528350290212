import { Fragment, useCallback, useContext } from 'react';
import {
    Checkbox,
    Container,
    TableCell,
    TableRow,
    makeStyles,
} from '@material-ui/core';
import { QUOTE_ACTION } from '../constants';
import { QuoteDispatchContext } from '../context';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    },
    tableRow: {
        width: '80%',
    },
    tableCell: {
        display: 'flex',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    tradeCell: {
        align: 'left',
        width: 140,
    },
    descriptionCell: {
        align: 'left',
        width: 500,
        borderBottom: 0,
    },
    numberCell: {
        align: 'right',
        textAlign: 'right',
        width: 80,
    },
}));

const Entry = ({ entry }: { entry: any }) => {
    const dispatch: any = useContext(QuoteDispatchContext);
    const classes = useStyles();

    const updateEntry = useCallback(
        ({ key, value }: { key: string; value: any }) => {
            dispatch({
                type: QUOTE_ACTION.UPDATE_ENTRY,
                payload: {
                    key,
                    value,
                    entryId: entry.id,
                },
            });
        },
        [entry, dispatch]
    );

    return (
        <Container className={classes.container}>
            <Fragment>
                <Checkbox
                    disableRipple
                    checked={entry.isSelected ?? false}
                    onChange={() => {
                        updateEntry({
                            key: 'isSelected',
                            value: !entry.isSelected,
                        });
                    }}
                />
            </Fragment>
            <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                    Trade: {entry.trade}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    Description: {entry.description}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    Quantity: {entry.quantity}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    UOM: {entry.uom}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    Rate: {entry.rate_dollars}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    Total: {entry.total_dollars}
                </TableCell>
            </TableRow>
        </Container>
    );
};

// const entryPropsAreEqual = (prevEntry: any, newEntry: any) => {
//     return prevEntry.entry === newEntry.entry;
// };

export default Entry;
// export default memo(Entry);
