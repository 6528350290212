import { makeStyles } from '@material-ui/core/styles';
import { TableHead, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tradeCell: {
        align: 'left',
        width: 140,
    },
    contractRefCell: {
        align: 'left',
        width: 100,
    },
    descriptionCell: {
        align: 'left',
        width: 400,
    },
    numberCell: {
        align: 'right',
        textAlign: 'right',
    },
}));

const EntryHeader = () => {
    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell className={classes.tradeCell}>Trade</TableCell>
                <TableCell className={classes.contractRefCell}>
                    Contract Ref
                </TableCell>
                <TableCell className={classes.descriptionCell}>
                    Description
                </TableCell>
                <TableCell className={classes.numberCell}>UOM</TableCell>
                <TableCell className={classes.numberCell}>Quantity</TableCell>
                <TableCell className={classes.numberCell}>Rate ($)</TableCell>
                <TableCell className={classes.numberCell}>Total ($)</TableCell>
                <TableCell className={classes.numberCell}>
                    Comp. Total ($)
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default EntryHeader;
