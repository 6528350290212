import React from "react";

import { Container, makeStyles } from "@material-ui/core";

import UploadNewQuote from '../../components/Forms/UploadNewQuoteForm';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    width: '80vw',
  }
}))

const NewQuote = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <UploadNewQuote />
    </Container>
  );
};

export default NewQuote;
