import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import {
    Container,
    Box,
    Typography,
    FormGroup,
    Button,
    FormControl,
    TextField,
    Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getCompanyHostName } from '../../../utils';

const SUBMIT_LOGIN = gql`
    mutation login($email: String!, $password: String!, $company: String!) {
        login(email: $email, password: $password, company: $company) {
            token
            company {
                id
                name
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '3rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        maxWidth: '6rem',
        margin: theme.spacing(2),
    },
    title: {
        lineHeight: '1.15',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    formGroup: {
        margin: theme.spacing(2),
    },
    smallFormControl: {
        margin: theme.spacing(1),
    },
    button: {
        backgroundColor: 'blue',
    },
    link: {
        margin: theme.spacing(7),
        fontSize: '0.8rem',
        color: 'rgba(0, 10, 80, 0.8)',
        cursor: 'pointer',
    },
}));

const defaultCredentials = {
    email: '',
    password: '',
    company: getCompanyHostName(),
};

const LoginForm = () => {
    const classes = useStyles();

    const [credentials, setCredentials] = useState(defaultCredentials);
    const [error, setError] = useState('');
    const { push } = useHistory();

    const onCompletedSubmitLogin = useCallback(
        (data) => {
            try {
                localStorage.setItem('api_token', data.login.token);
                localStorage.setItem(
                    'company',
                    JSON.stringify(data.login.company)
                );
                push('/');
            } catch {
                setError('Something went wrong.');
            }
        },
        [push, setError]
    );

    const onErrorSubmitLogin = useCallback(
        (error) => {
            setError(error.message);
        },
        [setError]
    );

    const [submitLogin, { loading: submittingLogin }] = useMutation(
        SUBMIT_LOGIN,
        {
            onCompleted: onCompletedSubmitLogin,
            onError: onErrorSubmitLogin,
        }
    );

    const _handleUpdate = useCallback(
        (field, value) => {
            setError('');
            setCredentials((currentCredentials) => {
                return {
                    ...currentCredentials,
                    [field]: value,
                };
            });
        },
        [setCredentials]
    );

    const _handleSubmit = useCallback(async () => {
        await submitLogin({
            variables: {
                ...credentials,
            },
        });
    }, [credentials, submitLogin]);

    return (
        <Container className={classes.container}>
            <Box component="img" src="/logo.jpg" className={classes.logo} />
            <Typography className={classes.title}>QuoteCheck Login</Typography>
            <FormGroup className={classes.formGroup}>
                <FormControl className={classes.smallFormControl}>
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        size="small"
                        value={credentials.email || ''}
                        onChange={({ target: { value } }) => {
                            _handleUpdate('email', value);
                        }}
                        disabled={submittingLogin}
                    />
                </FormControl>
                <FormControl className={classes.smallFormControl}>
                    <TextField
                        label="Password"
                        variant="outlined"
                        size="small"
                        type="password"
                        value={credentials.password || ''}
                        onChange={({ target: { value } }) => {
                            _handleUpdate('password', value);
                        }}
                        disabled={submittingLogin}
                    />
                </FormControl>
                {error && <Typography>{error}</Typography>}
            </FormGroup>
            <Button
                variant="contained"
                disableElevation={true}
                onClick={_handleSubmit}
                color="primary"
                disabled={submittingLogin}
            >
                Submit
            </Button>
            <Link
                className={classes.link}
                onClick={() => {
                    push('/forgot-password');
                }}
                underlineHover
            >
                Forgot password?
            </Link>
        </Container>
    );
};

export default LoginForm;
