import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import DayJsUtils from '@date-io/dayjs';
import enAu from 'dayjs/locale/en-au';
import {
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  ApolloProvider,
} from "@apollo/client";
import client from './graphql/client';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <MuiPickersUtilsProvider locale={enAu} utils={DayJsUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
