import React from 'react';

import { TableContainer, Table, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Row from './Row';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

const Quotes = ({ quotes, loading }) => {
    const classes = useStyles();

    return (
        <TableContainer className={classes.container}>
            <Typography>Quotes</Typography>
            <Table className={classes.table}>
                <Header />
                <Row quotes={quotes} loading={loading} />
            </Table>
        </TableContainer>
    );
};

export default Quotes;
