import { Fragment } from 'react';
import Highlight from './Highlight';
import { RenderHighlightsProps } from '@react-pdf-viewer/highlight';

const HighlightEntry = ({
    entry,
    highlightProps,
}: {
    entry: any;
    highlightProps: RenderHighlightsProps;
}) => {
    const tradeHighlightAreas = entry.trade_highlight_areas ?? [];
    const descriptionHighlightAreas = entry.description_highlight_areas ?? [];
    const quantityHighlightAreas = entry.quantity_highlight_areas ?? [];
    const uomHighlightAreas = entry.uom_highlight_areas ?? [];
    const rateHighlightAreas = entry.rate_highlight_areas ?? [];
    const totalHighlightAreas = entry.total_highlight_areas ?? [];

    const highlightAreas = [
        tradeHighlightAreas,
        descriptionHighlightAreas,
        quantityHighlightAreas,
        uomHighlightAreas,
        rateHighlightAreas,
        totalHighlightAreas,
    ];

    return (
        <Fragment>
            {highlightAreas.map((highlightArea, index) => {
                if (!highlightArea) return <></>;
                return (
                    <Highlight
                        highlightAreas={highlightArea}
                        highlightProps={highlightProps}
                        key={`highlight-entry-${entry.id}-${index}`}
                    />
                );
            })}
        </Fragment>
    );
};

export default HighlightEntry;
// export default memo(Highlight);
