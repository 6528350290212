import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, Button, Typography } from '@material-ui/core';

import Row from './Row';

const useStyles = makeStyles((theme) => ({
    typography: {
        margin: theme.spacing(2),
    },
    root: {
        '& > *': {
            // borderBottom: "unset",
        },
        backgroundColor: 'rgba(0, 60, 0, 0.1)',
    },
}));

const UPDATE_CURRENT_RATE = gql`
    mutation UpdateCurrentRate($rate_input: RateInput!) {
        editCurrentRate(rate_input: $rate_input) {
            id
        }
    }
`;

const buildUpdateRateVariables = (rate) => {
    const { rate_dollars } = rate;

    return {
        rate_input: {
            id: rate.id,
            rate_dollars,
        },
    };
};

const Rates = ({
    rateBase,
    rates,
    currentAppliedRate,
    isOpen,
    refetchRates,
    loadingRates,
}) => {
    const classes = useStyles();

    const [edit, setEdit] = useState(false);
    const [tempCurrentAppliedRate, setTempCurrentAppliedRate] =
        useState(currentAppliedRate);

    useEffect(() => {
        setTempCurrentAppliedRate(currentAppliedRate);
    }, [currentAppliedRate]);

    const handleUpdateValue = useCallback(
        (key, value) => {
            const updatedRate = {
                ...tempCurrentAppliedRate,
                [key]: value,
            };
            setTempCurrentAppliedRate(updatedRate);
        },
        [setTempCurrentAppliedRate, tempCurrentAppliedRate]
    );

    const onCompletedRateMutation = useCallback(() => {
        refetchRates();
        setEdit(false);
    }, [refetchRates]);

    const [updateRateMutation, { loading }] = useMutation(UPDATE_CURRENT_RATE, {
        onCompleted: onCompletedRateMutation,
        onError: (error) => {
            console.log('ERROR', error);
        },
    });

    const handleClickEdit = useCallback(() => {
        setEdit((currentEdit) => !currentEdit);
        setTempCurrentAppliedRate(currentAppliedRate);
    }, [setEdit, setTempCurrentAppliedRate, currentAppliedRate]);

    const handleClickSave = useCallback(() => {
        updateRateMutation({
            variables: buildUpdateRateVariables(tempCurrentAppliedRate),
        });
    }, [updateRateMutation, tempCurrentAppliedRate]);

    if (!isOpen) return null;

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell colSpan={8}>
                    {edit ? (
                        <>
                            <Button
                                onClick={handleClickEdit}
                                disabled={loading}
                            >
                                CANCEL
                            </Button>
                            <Button
                                onClick={handleClickSave}
                                disabled={loading}
                            >
                                SAVE
                            </Button>
                        </>
                    ) : (
                        <Button
                            onClick={handleClickEdit}
                            disabled={loadingRates}
                        >
                            Edit
                        </Button>
                    )}
                    {loadingRates && <Typography>Loading...</Typography>}
                </TableCell>
            </TableRow>
            <TableRow className={classes.root}>
                <TableCell colSpan={8}>
                    <Typography>Current Applied Rate</Typography>
                </TableCell>
            </TableRow>
            {tempCurrentAppliedRate && (
                <Row
                    id={`current-applied-rate-row-${tempCurrentAppliedRate.id}`}
                    rateBase={rateBase}
                    rate={tempCurrentAppliedRate}
                    edit={edit}
                    handleUpdateValue={handleUpdateValue}
                />
            )}
            <TableRow className={classes.root}>
                <TableCell colSpan={8}>
                    <Typography>All Rates</Typography>
                </TableCell>
            </TableRow>
            {rates.map((rate) => (
                <Row rateBase={rateBase} rate={rate} />
            ))}
        </>
    );
};

export default Rates;
