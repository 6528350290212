import React, { Fragment, useCallback, useContext } from 'react';

import {
    Typography,
    TextField,
    InputLabel,
    makeStyles,
    Box,
    FormControl,
    Divider,
} from '@material-ui/core';
import Select from '../../../FormHelpers/Select';
import {
    EditContext,
    QuoteContext,
    QuoteDispatchContext,
} from '../../../Quote/context';
import { QUOTE_ACTION } from '../../../Quote/constants';

const stateOptions = [
    { value: 'NSW', label: 'NSW' },
    { value: 'VIC', label: 'VIC' },
    { value: 'QLD', label: 'QLD' },
    { value: 'WA', label: 'WA' },
    { value: 'SA', label: 'SA' },
    { value: 'TAS', label: 'TAS' },
    { value: 'NT', label: 'NT' },
    { value: 'ACT', label: 'ACT' },
];

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 200,
    },
    smallFormControl: {
        margin: theme.spacing(2),
        width: 140,
    },
    largeFormControl: {
        margin: theme.spacing(2),
        width: 300,
    },
    divider: {
        width: 200,
    },
}));

const Address = () => {
    const edit = useContext(EditContext);
    const quote = useContext(QuoteContext);
    const address = quote.address_component;
    const dispatch = useContext(QuoteDispatchContext);
    const classes = useStyles();

    const updateAddress = useCallback(
        (key, value) => {
            dispatch({
                type: QUOTE_ACTION.UPDATE_ADDRESS,
                payload: {
                    key,
                    value,
                },
            });
        },
        [dispatch]
    );

    return (
        <Fragment>
            <Box>
                <Typography>Situation Address</Typography>
                <Divider className={classes.divider} />
                <FormControl className={classes.smallFormControl}>
                    <TextField
                        label="Unit Number"
                        variant="outlined"
                        size="small"
                        value={address.unit_number || ''}
                        onChange={({ target: { value } }) => {
                            updateAddress('unit_number', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
                <FormControl className={classes.smallFormControl}>
                    <TextField
                        label="Street Number"
                        variant="outlined"
                        size="small"
                        value={address.street_number || ''}
                        onChange={({ target: { value } }) => {
                            updateAddress('street_number', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
                <FormControl className={classes.largeFormControl}>
                    <TextField
                        label="Street"
                        variant="outlined"
                        size="small"
                        value={address.street || ''}
                        onChange={({ target: { value } }) => {
                            updateAddress('street', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
            </Box>
            <Box>
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Suburb"
                        variant="outlined"
                        size="small"
                        value={address.suburb || ''}
                        onChange={({ target: { value } }) => {
                            updateAddress('suburb', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Postcode"
                        variant="outlined"
                        size="small"
                        value={address.postcode || ''}
                        onChange={({ target: { value } }) => {
                            updateAddress('postcode', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
                <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                >
                    <InputLabel>State</InputLabel>
                    <Select
                        label="State"
                        id={`state-input`}
                        options={stateOptions}
                        value={address.state || ''}
                        onChange={({ target: { value } }) => {
                            updateAddress('state', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
            </Box>
        </Fragment>
    );
};

export default Address;
