import React, { useCallback, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  InputLabel,
  FormControl,
  TextField,
} from "@material-ui/core";

import Select from '../../FormHelpers/Select';

const useStyles = makeStyles((theme) => ({
  typography: {
    margin: theme.spacing(2),
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  titleElement: {
    margin: '5px',
    padding: '5px',
  },
  form: {
    borderRadius: '25px',
    border: '2px solid rgba(0,0,0,0.2)',
    padding: '20px',
    margin: '10px'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  descriptionField: {
    minWidth: 600,
  },
  marginField: {
    minWidth: 100,
  }
}));

const GET_OPTIONS = gql`
  query getOptions {
    trades {
      value: id
      label: name
    }
    uoms {
      value: id
      label: name
    }
    companies {
      value: id
      label: name
    }
    actions
  }
`;

const otherOption = {
  value: -1,
  label: 'OTHER'
};

const Form = ({ rate, updateRate }) => {
  const classes = useStyles();
  const [tradeOptions, setTradeOptions] = useState([]);
  const [uomOptions, setUomOptions] = useState([]);
  const [actionOptions, setActionOptions] = useState([]);

  const onCompletedGetOptions = useCallback((data) => {
    if (data) {
      const {
        trades: tradeOptionResults = [],
        uoms: uomOptionsResults = [],
        actions: actionOptionsResults
      } = data || {};
      setTradeOptions([otherOption, ...tradeOptionResults]);
      setUomOptions([otherOption, ...uomOptionsResults]);
      const convertedActionOptions = actionOptionsResults.map((action, index) => ({
        value: index + 1,
        label: action
      }));
      setActionOptions([otherOption, ...convertedActionOptions]);
    }
  }, [setTradeOptions, setUomOptions, setActionOptions]);

  useQuery(GET_OPTIONS, { onCompleted: onCompletedGetOptions })

  return (
    <>
      <Box>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <InputLabel>Trade</InputLabel>
          <Select
            label="Trade"
            id={`trade-dropdown-input`}
            options={tradeOptions}
            value={rate.trade_id || ""}
            onChange={({ target: { value } }) => {
              updateRate('trade_id', value);
            }}
          />
        </FormControl>
        {rate.trade_id === otherOption.value &&
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              required
              id={`trade-input`}
              className={classes.textField}
              label="Trade"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              value={rate.trade || ""}
              onChange={({ target: { value } }) => {
                updateRate('trade', value);
              }}
              size="small"
            />
          </FormControl>
        }
      </Box>
      <Box>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <InputLabel>UOM</InputLabel>
          <Select
            label="UOM"
            id={`uom-dropdown-input`}
            options={uomOptions}
            value={rate.uom_id || ""}
            onChange={({ target: { value } }) => {
              updateRate('uom_id', value);
            }}
          />
        </FormControl>
        {rate.uom_id === otherOption.value &&
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              required
              id={`uom-input`}
              className={classes.textField}
              label="UOM"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              value={rate.uom || ""}
              onChange={({ target: { value } }) => {
                updateRate('uom', value);
              }}
              size="small"
            />
          </FormControl>
        }
      </Box>
      <Box>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <InputLabel>Action</InputLabel>
          <Select
            label="Action"
            id={`action-dropdown-input`}
            options={actionOptions}
            value={rate.action_id || ""}
            onChange={({ target: { value } }) => {
              const actionOption = actionOptions.find(actionOption => actionOption.value === value);
              if (value === otherOption.value) {
                return updateRate('action_id', value, 'action', null);
              }
              return updateRate('action_id', value, 'action', actionOption.label);
            }}
          />
        </FormControl>
        {rate.action_id === otherOption.value &&
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              required
              id={`action-input`}
              className={classes.textField}
              label="Action"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              value={rate.action || ""}
              onChange={({ target: { value } }) => {
                updateRate('action', value);
              }}
              size="small"
            />
          </FormControl>
        }
      </Box>
      <Box>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            required
            id={`item-input`}
            className={classes.descriptionField}
            label="Item"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            value={rate.item || ""}
            onChange={({ target: { value } }) => {
              updateRate('item', value);
            }}
            size="small"
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            required
            id={`rate-in-dollars-input`}
            className={classes.textField}
            label="Rate ($)"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            value={rate.rate_dollars || ""}
            onChange={({ target: { valueAsNumber } }) => {
              const value = isNaN(valueAsNumber) ? null : valueAsNumber;
              updateRate('rate_dollars', value);
            }}
            size="small"
          />
        </FormControl>
      </Box>
    </>
  );
};

export default Form;
