import QuoteDetails from '../../Forms/EnterNewQuoteForm/QuoteDetails';

const Information = () => {
    return (
        <>
            <QuoteDetails />
        </>
    );
};

export default Information;
