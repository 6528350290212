import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TableRow, TableCell } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';

import { formatDate } from '../../utils/format';
import Rates from '../Rates';

const useStyles = makeStyles((theme) => ({
    typography: {
        margin: theme.spacing(2),
    },
    formControlSelect: {
        margin: theme.spacing(2),
        width: 180,
    },
    formControlNumber: {
        margin: theme.spacing(2),
        width: 100,
    },
    iconButton: {
        margin: theme.spacing(2),
    },
    root: {
        '& > *': {
            // borderBottom: "unset",
        },
    },
    tradeCell: {
        align: 'left',
        width: 140,
    },
    descriptionCell: {
        align: 'left',
        width: 500,
    },
    numberCell: {
        align: 'right',
        textAlign: 'right',
    },
    redCell: {
        align: 'right',
        textAlign: 'right',
        backgroundColor: '#f44336',
    },
    yellowCell: {
        align: 'right',
        textAlign: 'right',
        backgroundColor: '#ffd966',
    },
    greenCell: {
        align: 'right',
        textAlign: 'right',
        backgroundColor: '#93c47d',
    },
}));

const getCurrentAppliedRateDollars = (currentAppliedRate) => {
    if (!currentAppliedRate) return 'N/A';
    return currentAppliedRate.rate_dollars;
};

const getCurrentAppliedRateIsActive = (currentAppliedRate) => {
    if (!currentAppliedRate) return 'N/A';
    return currentAppliedRate.is_active ? 'TRUE' : 'FALSE';
};

const getCurrentAppliedRateCreatedDate = (currentAppliedRate) => {
    if (!currentAppliedRate) return 'N/A';
    return formatDate(currentAppliedRate.created_at, 'DD/MM/YYYY');
};

const Row = ({ rateBase, refetchRates, loadingRates }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.tradeCell}>
                    {rateBase.trade}
                </TableCell>
                <TableCell className={classes.descriptionCell}>
                    {rateBase.item}
                </TableCell>
                <TableCell className={classes.tradeCell}>
                    {rateBase.action}
                </TableCell>
                <TableCell className={classes.numberCell}>
                    {rateBase.uom}
                </TableCell>
                <TableCell className={classes.numberCell}>
                    {getCurrentAppliedRateDollars(
                        rateBase.current_applied_rate
                    )}
                </TableCell>
                <TableCell className={classes.numberCell}>
                    {getCurrentAppliedRateIsActive(
                        rateBase.current_applied_rate
                    )}
                </TableCell>
                <TableCell className={classes.numberCell}>
                    {getCurrentAppliedRateCreatedDate(
                        rateBase.current_applied_rate
                    )}
                </TableCell>
            </TableRow>
            <Rates
                id={`rate-preview-${rateBase.id}`}
                className={classes.root}
                rateBase={rateBase}
                rates={rateBase.rates}
                currentAppliedRate={rateBase.current_applied_rate}
                isOpen={isOpen}
                refetchRates={refetchRates}
                loadingRates={loadingRates}
            />
        </Fragment>
    );
};

export default Row;
