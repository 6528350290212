import React, { useContext } from 'react';

import Location from './Location';
import { QuoteContext } from '../context';

const Locations = ({ handleSave }) => {
    const quote = useContext(QuoteContext);

    if (!quote) return;

    return quote.locations.map((location) => {
        return (
            <Location
                location={location}
                handleSave={handleSave}
                key={`location-key-${location.id}`}
            />
        );
    });
};

export default Locations;
