import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, TextField, Box, FormControl } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  measurement: {
    width: 200,
  }
}));

const RoomHeader = ({ room, removeRoom, updateRoom }) => {
  const classes = useStyles();
  return (
    <Container>
      <Box>
      <FormControl variant="outlined" size="small" className={classes.formControl}>
          <TextField
            id={`room-name-input-${room.id}`}
            label="Location"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            size="small"
            type="text"
            value={room.name || ""}
            onChange={({ target: { value } }) => {
              updateRoom(room.id, 'name', value);
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <TextField
            id={`depth-input-${room.id}`}
            label="Depth (m)"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            size="small"
            className={classes.measurement}
            type="number"
            value={room.depth || ""}
            onChange={({ target: { valueAsNumber } }) => {
              const value = isNaN(valueAsNumber) ? null : valueAsNumber;
              updateRoom(room.id, 'depth', value);
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <TextField
            id={`width-input-${room.id}`}
            label="Width (m)"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.measurement}
            type="number"
            value={room.width || ""}
            onChange={({ target: { valueAsNumber } }) => {
              const value = isNaN(valueAsNumber) ? null : valueAsNumber;
              updateRoom(room.id, 'width', value);
            }}
            variant="outlined"
            size="small"
          />
        </FormControl>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <TextField
            required
            id={`height-input-${room.id}`}
            label="Height (m)"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.measurement}
            type="number"
            value={room.height || ""}
            onChange={({ target: { valueAsNumber } }) => {
              const value = isNaN(valueAsNumber) ? null : valueAsNumber;
              updateRoom(room.id, 'height', value);
            }}
            variant="outlined"
            size="small"
          />
        </FormControl>
      </Box>
    </Container>
  )
};

export default RoomHeader;