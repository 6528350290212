import React, { Fragment } from "react";
import AppBarWrapper from "../components/Layout/AppBarWrapper";

const Layout = ({ children }) => {
  return (
    <Fragment>
      <AppBarWrapper />
      {children}
    </Fragment>
  );
};

const withLayout = (component) => <Layout>{component}</Layout>;

export { withLayout };
