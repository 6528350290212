import React, { useCallback, useState, useReducer } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, Typography } from '@material-ui/core';

import { GET_QUOTE, GET_OPTIONS, EDIT_QUOTE } from './gql';

import {
    EditContext,
    OptionsContext,
    QuoteContext,
    QuoteDispatchContext,
    SavedQuoteContext,
    SavedQuoteDispatchContext,
} from './context';
import { QUOTE_ACTION } from './constants';
import { quoteReducer, savedQuoteReducer } from './reducer';
import { generateQuoteInputVariables } from './utils';
import Information from './Information';
import Locations from './Location';
import Download from './Download';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        minWidth: '90vw',
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const Quote = () => {
    const params = useParams();
    const { id: quoteId } = params as any;

    // add context for these things too
    const [edit, setEdit] = useState(false);
    const [options, setOptions] = useState({
        rates: [],
        trades: [],
        formats: [],
        companies: [],
    });
    const [quote, dispatch] = useReducer(quoteReducer, null);
    const [savedQuote, savedQuoteDispatch] = useReducer(
        savedQuoteReducer,
        null
    );

    const classes = useStyles();

    const onCompletedGetQuote = useCallback(
        (data) => {
            if (data) {
                const { quote } = data;
                dispatch({
                    type: QUOTE_ACTION.SET_QUOTE,
                    payload: quote,
                });
                savedQuoteDispatch({
                    type: QUOTE_ACTION.SET_QUOTE,
                    payload: quote,
                });
            }
        },
        [dispatch, savedQuoteDispatch]
    );

    const onCompletedGetOptions = useCallback(
        (data) => {
            if (data) {
                const {
                    trades = [],
                    rates = [],
                    companies = [],
                    formats = [],
                } = data || {};
                setOptions({
                    trades,
                    rates,
                    companies,
                    formats,
                });
            }
        },
        [setOptions]
    );

    /**********************************************************************
     * GRAPHQL
     **********************************************************************/
    const { refetch: refetchQuote, loading: loadingQuote } = useQuery(
        GET_QUOTE,
        {
            variables: {
                id: parseInt(quoteId),
            },
            onCompleted: onCompletedGetQuote,
            notifyOnNetworkStatusChange: true,
        }
    );

    useQuery(GET_OPTIONS, {
        variables: {
            options: {
                quote_id: parseInt(quoteId),
            },
        },
        onCompleted: onCompletedGetOptions,
    });

    const [editQuote, { loading: editQuoteLoading }] = useMutation(EDIT_QUOTE, {
        onCompleted: () => {
            setEdit(false);
            refetchQuote();
        },
    });

    const handleSaveClick = useCallback(() => {
        // todo: add a check so we don't save over and over
        // i.e.: if (editQuoteLoading) return;
        editQuote({
            variables: {
                quote_input: generateQuoteInputVariables(quote),
            },
        });
    }, [editQuote, quote]);

    const handleEditClick = useCallback(() => {
        setEdit((currentState) => !currentState);
    }, [setEdit]);

    const handleCancelClick = useCallback(() => {
        setEdit((currentState) => !currentState);

        dispatch({
            type: QUOTE_ACTION.SET_QUOTE,
            payload: savedQuote,
        });
    }, [setEdit, savedQuote]);

    const handleAddFieldsClick = useCallback(() => {
        window.open(`/quote/${quoteId}/addFields`, '_blank');
    }, [quoteId]);

    if (loadingQuote && !quote) {
        return (
            <Container className={classes.container}>
                <Typography>Loading...</Typography>
            </Container>
        );
    }

    if (!quote) {
        return (
            <Container className={classes.container}>
                <Typography>Quote not found</Typography>
            </Container>
        );
    }

    return (
        <EditContext.Provider value={edit}>
            <SavedQuoteContext.Provider value={savedQuote}>
                <SavedQuoteDispatchContext.Provider
                    value={savedQuoteDispatch as any}
                >
                    <QuoteContext.Provider value={quote}>
                        <QuoteDispatchContext.Provider value={dispatch as any}>
                            <OptionsContext.Provider value={options as any}>
                                <Container className={classes.container}>
                                    <Typography>Quote</Typography>
                                    {edit ? (
                                        <>
                                            <Button
                                                onClick={handleCancelClick}
                                                disabled={loadingQuote}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={handleSaveClick}
                                                disabled={loadingQuote}
                                            >
                                                Save
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            onClick={handleEditClick}
                                            disabled={loadingQuote}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                    {editQuoteLoading && (
                                        <Typography>Saving...</Typography>
                                    )}
                                    <Download quoteId={quote.id} />
                                    <Button
                                        onClick={handleAddFieldsClick}
                                        disabled={loadingQuote}
                                    >
                                        Add Fields
                                    </Button>
                                    <Container>
                                        <Information />
                                    </Container>
                                    <Container>
                                        <Locations
                                            handleSave={handleSaveClick}
                                        />
                                    </Container>
                                </Container>
                            </OptionsContext.Provider>
                        </QuoteDispatchContext.Provider>
                    </QuoteContext.Provider>
                </SavedQuoteDispatchContext.Provider>
            </SavedQuoteContext.Provider>
        </EditContext.Provider>
    );
};

export default Quote;
