import React from 'react';

import Header from './Header';
import Row from './Row';

const RateBases = ({rateBases, refetchRates, loadingRates}) => {
    return (
        <>
            <Header />
            {rateBases.map(rateBase => (
                <Row
                    id={`rate-base-row-${rateBase.id}`}
                    rateBase={rateBase}
                    refetchRates={refetchRates}
                    loadingRates={loadingRates}
                />
            ))

            }
        </>
    )
};

export default RateBases;