import React, {
    useEffect,
    useState,
    useCallback,
    useContext,
    Fragment,
} from 'react';
import { filter } from 'lodash';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { OptionsContext, QuoteContext } from '../context';
import CheckboxWithLabel from '../../FormHelpers/Checkbox';

const filterTypes = {
    QUOTE_CHECK: 'QUOTE_CHECK',
    BUILDER: 'BUILDER',
    COMPANY: 'COMPANY',
};

const columns = [
    {
        field: 'trade',
        headerName: 'Trade',
        width: 150,
        editable: true,
    },
    {
        field: 'item',
        headerName: 'Item',
        width: 450,
        editable: true,
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 200,
        editable: true,
    },
    {
        field: 'rate_dollars',
        headerName: 'Rate ($)',
        width: 150,
        editable: true,
    },
    {
        field: 'uom',
        headerName: 'UOM',
        width: 120,
        editable: true,
    },
    {
        field: 'company_name',
        headerName: 'Company',
        width: 150,
        editable: true,
    },
    {
        field: 'builder_name',
        headerName: 'Builder',
        width: 150,
        editable: true,
    },
    {
        field: 'state',
        headerName: 'State',
        width: 90,
        editable: true,
    },
    {
        field: 'region_type',
        headerName: 'Region',
        width: 90,
        editable: true,
    },
    {
        field: 'is_active',
        headerName: 'Active',
        width: 90,
        editable: true,
    },
    { field: 'contract_ref', headerName: 'Contract Ref', width: 90 },
    { field: 'id', headerName: 'QC Id', width: 90 },
];

const findSelectedRate = (currentRateSelection, newRateSelection) => {
    const uniuqeRateSelection = newRateSelection.filter((rate) => {
        return currentRateSelection.indexOf(rate) === -1;
    });
    return uniuqeRateSelection;
};

const createTradeItemFilter = (trade) => {
    return { columnField: 'trade', operatorValue: 'contains', value: trade };
};

const ComparisonRateOptions = ({ selectedComparisonRate, onRateSelection }) => {
    const options = useContext(OptionsContext);
    const quote = useContext(QuoteContext);

    const [selectedRate, setSelectedRate] = useState([]);
    const [itemFilters, setItemFilters] = useState([]);
    const [isQuotecheckFilterOn, setIsQuotecheckFilterOn] = useState(true);
    const [isCompanyFilterOn, setIsCompanyFilterOn] = useState(false);
    const [isBuilderFilterOn, setIsBuilderFilterOn] = useState(false);

    useEffect(() => {
        if (selectedComparisonRate && selectedComparisonRate.id) {
            setSelectedRate([selectedComparisonRate.id]);
            const tradeItemFilter = createTradeItemFilter(
                selectedComparisonRate.trade
            );
            setItemFilters([tradeItemFilter]);
        } else {
            setSelectedRate([]);
        }
    }, [selectedComparisonRate, setSelectedRate, setItemFilters]);

    const handleOnSelection = useCallback(
        (rateSelection) => {
            if (Array.isArray(rateSelection) && rateSelection.length === 0) {
                return;
            }

            const currentRateSelection = selectedRate || [];
            const newRateSelection = rateSelection;

            const updatedRateSelection = findSelectedRate(
                currentRateSelection,
                newRateSelection
            );

            setSelectedRate(updatedRateSelection);
            onRateSelection(updatedRateSelection[0]);
        },
        [setSelectedRate, onRateSelection, selectedRate]
    );

    const handleFilterChange = useCallback(
        (event) => {
            const { items } = event;
            setItemFilters(items);
        },
        [setItemFilters]
    );

    const filteredRatesByFilterType = useCallback(() => {
        const rates = options.rates ?? [];
        let filteredRates = rates;

        if (isQuotecheckFilterOn && isCompanyFilterOn && isBuilderFilterOn) {
            return filteredRates;
        }

        if (isQuotecheckFilterOn && isCompanyFilterOn && !isBuilderFilterOn) {
            filteredRates = filter(filteredRates, (filteredRate) => {
                return !filteredRate.builder_name;
            });

            return filteredRates;
        }

        if (isQuotecheckFilterOn && !isCompanyFilterOn && isBuilderFilterOn) {
            filteredRates = filter(filteredRates, (filteredRate) => {
                return !filteredRate.company_name;
            });

            return filteredRates;
        }

        if (!isQuotecheckFilterOn && isCompanyFilterOn && isBuilderFilterOn) {
            filteredRates = filter(filteredRates, (filteredRate) => {
                return filteredRate.company_name && filteredRate.builder_name;
            });

            return filteredRates;
        }

        if (isQuotecheckFilterOn) {
            filteredRates = filter(filteredRates, (filteredRate) => {
                return !filteredRate.company_name && !filteredRate.builder_name;
            });

            return filteredRates;
        }

        if (isCompanyFilterOn) {
            filteredRates = filter(filteredRates, (filteredRate) => {
                return !!filteredRate.company_name;
            });

            return filteredRates;
        }

        if (isBuilderFilterOn) {
            filteredRates = filter(filteredRates, (filteredRate) => {
                return !!filteredRate.builder_name;
            });

            return filteredRates;
        }

        return filteredRates;
    }, [
        isQuotecheckFilterOn,
        isCompanyFilterOn,
        isBuilderFilterOn,
        options.rates,
    ]);

    const handleCheckboxFilterChange = useCallback(
        (checkboxFilterType) => {
            switch (checkboxFilterType) {
                case filterTypes.QUOTE_CHECK:
                    setIsQuotecheckFilterOn((current) => !current);
                    break;
                case filterTypes.COMPANY:
                    setIsCompanyFilterOn((current) => !current);
                    break;
                case filterTypes.BUILDER:
                    setIsBuilderFilterOn((current) => !current);
                    break;
                default:
                    break;
            }
        },
        [setIsQuotecheckFilterOn, setIsCompanyFilterOn, setIsBuilderFilterOn]
    );

    const generateCompanyFilterLabel = useCallback(() => {
        if (quote.company && quote.company.name) {
            return `Company (${quote.company.name})`;
        }

        return `Company`;
    }, [quote]);

    const generateBuilderFilterLabel = useCallback(() => {
        if (quote.builder && quote.builder.name) {
            return `Builder (${quote.builder.name})`;
        }

        return `Builder`;
    }, [quote]);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <Fragment>
                <CheckboxWithLabel
                    disableRipple
                    color="primary"
                    label="QuoteCheck"
                    checked={isQuotecheckFilterOn}
                    onChange={() => {
                        handleCheckboxFilterChange(filterTypes.QUOTE_CHECK);
                    }}
                />
                <CheckboxWithLabel
                    disableRipple
                    color="primary"
                    label={generateCompanyFilterLabel()}
                    checked={isCompanyFilterOn}
                    onChange={() => {
                        handleCheckboxFilterChange(filterTypes.COMPANY);
                    }}
                />
                <CheckboxWithLabel
                    disableRipple
                    color="primary"
                    label={generateBuilderFilterLabel()}
                    checked={isBuilderFilterOn}
                    onChange={() => {
                        handleCheckboxFilterChange(filterTypes.BUILDER);
                    }}
                />
            </Fragment>
            <DataGrid
                rows={filteredRatesByFilterType()}
                columns={columns}
                filterModel={{
                    items: itemFilters,
                }}
                components={{
                    Toolbar: GridToolbar,
                }}
                pageSize={100}
                rowsPerPageOptions={[100]}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={handleOnSelection}
                selectionModel={selectedRate}
                onFilterModelChange={handleFilterChange}
            />
        </div>
    );
};

export default ComparisonRateOptions;
