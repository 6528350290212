import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Container } from '@material-ui/core';

import Entry from '../Entry';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
        width: '80vw',
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const Location = ({ location }: { location: any }) => {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Container>
                {location.entries.map((entry: any, index: number) => {
                    return (
                        <Entry entry={entry} key={`entry-key-${entry.id}`} />
                    );
                })}
            </Container>
        </Container>
    );
};

export default Location;
