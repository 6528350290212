import React from 'react';

import { TableRow, TableCell } from '@material-ui/core';

import { formatDate } from '../../../utils/format';

const Job = ({job, jobIndex}) => {
    return (
        <TableRow>
            <TableCell>{jobIndex}</TableCell>
            <TableCell>{job.status_details.label}</TableCell>
            <TableCell>{job.stage_details.label}</TableCell>
            <TableCell>{formatDate(job.created_at)}</TableCell>
        </TableRow>
    )
};

export default Job;