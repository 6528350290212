import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';

import EditableCell from '../FormHelpers/EditableCell';
import { formatDate } from '../../utils/format';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            // borderBottom: "unset",
        },
        backgroundColor: 'rgba(0, 60, 0, 0.1)',
    },
    tradeCell: {
        align: 'left',
        width: 140,
    },
    descriptionCell: {
        align: 'left',
        width: 500,
    },
    numberCell: {
        align: 'right',
        textAlign: 'right',
        width: 100,
    },
}));

const Row = ({ rateBase, rate, handleUpdateValue, edit }) => {
    const classes = useStyles();

    return (
        <TableRow className={classes.root}>
            <TableCell>{rate.id}</TableCell>
            <TableCell className={classes.tradeCell}>
                {rateBase.trade}
            </TableCell>
            <TableCell className={classes.descriptionCell}>
                {rateBase.item}
            </TableCell>
            <TableCell className={classes.tradeCell}>
                {rateBase.action}
            </TableCell>
            <TableCell className={classes.numberCell}>{rateBase.uom}</TableCell>
            <EditableCell
                className={classes.numberCell}
                editing={edit}
                updateValue={handleUpdateValue}
                tempValue={rate.rate_dollars}
                keyName="rate_dollars"
                type="number"
            />
            <TableCell className={classes.numberCell}>
                {rate.is_active ? 'TRUE' : 'FALSE'}
            </TableCell>
            <TableCell className={classes.numberCell}>
                {formatDate(rate.created_at, 'DD/MM/YYYY')}
            </TableCell>
        </TableRow>
    );
};

export default Row;
