import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableBody, Typography } from '@material-ui/core';

import Header from "./Header";
import Row from "./Row"

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650
    }
}));

const BatchPreview = ({ batches }) => {
    const classes = useStyles();
    return (
        <TableContainer>
            <Typography>Upload Log</Typography>
            <Table className={classes.table}>
                <Header />
                <TableBody>
                    {batches.map(batch => (
                        <Row batch={batch} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default BatchPreview;