import React, { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core';

import Batches from '../../components/Batches';

const GET_BATCHES = gql`
    query {
        batches {
            id
            trigger
            status_details {
                label
            }
            error
            jobs {
                id
                status_details {
                    label
                }
                stage_details {
                    label
                }
                stage
                error
                created_at
            }
            created_at
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        minWidth: "90vw"
    }
}))

const UploadLog = () => {
    const classes = useStyles();

    const [batches, setBatches] = useState([]);

    const onCompletedGetBatches = useCallback((data) => {
        const {
            batches: batchesData
        } = data;

        setBatches(batchesData ?? [])
    }, []);

    const { loading } = useQuery(GET_BATCHES, {
        onCompleted: onCompletedGetBatches
    })

    if (loading) {
        return (<Typography>Loading...</Typography>)
    }

    return (
        <Container className={classes.container}>
            <Batches batches={batches} />
        </Container>
    )
};

export default UploadLog;