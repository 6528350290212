import React, { Fragment } from 'react';

import LoginForm from '../../components/Forms/LoginForm';

const Login = () => {
    return (
        <Fragment>
            <LoginForm />
        </Fragment>
    )
};

export default Login;