export const QUOTE_ACTION = {
    SET_QUOTE: 'SET_QUOTE',
    RESET_QUOTE: 'RESET_QUOTE',
    ADD_ENTRY: 'ADD_ENTRY',
    REMOVE_ENTRY: 'REMOVE_ENTRY',
    UPDATE_QUOTE: 'UPDATE_QUOTE',
    UPDATE_ADDRESS: 'UPDATE_ADDRESS',
    UPDATE_CLAIM_INFO: 'UPDATE_CLAIM_INFO',
    UPDATE_INFO: 'UPDATE_INFO',
    UPDATE_ENTRY: 'UPDATE_ENTRY',
    UPDATE_RATE: 'UPDATE_RATE',
};
