import React, { useCallback, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";

import Form from './Form';

const ADD_RATE = gql`
  mutation AddRate($add_rate_input: AddRateInput!) {
    addRate(add_rate_input: $add_rate_input) {
      id
    }
  }
`;

const defaultNewRate = {
  trade: null,
  trade_id: null,
  item: null,
  action: null,
  uom_id: null,
  uom: null,
  rate_dollars: null
}

const generateAddRateVariables = (rate) => {
  const {
    trade,
    trade_id,
    item,
    action,
    uom_id,
    uom,
    rate_dollars
  } = rate;

  return {
    add_rate_input: {
      trade,
      trade_id: trade_id < 0 ? null : trade_id,
      item,
      action,
      uom_id: uom_id < 0 ? null : uom_id,
      uom,
      rate_dollars
    }
  }
}

const AddRate = ({ refetchRates }) => {
  const [rate, setRate] = useState(defaultNewRate);
  const [addRate, setAddRate] = useState(false);

  const onCompletedAddRate = useCallback(() => {
    setAddRate(false);
    setRate(defaultNewRate);
    refetchRates();
  }, [refetchRates])

  const [addRateMutation, { submittingAddRate }] = useMutation(ADD_RATE, {
    onCompleted: onCompletedAddRate,
    onError: () => { console.log('ERROR') }
  })

  const handleAddRateClick = useCallback(() => {
    setAddRate(currentAddRate => !currentAddRate);
  }, [setAddRate])

  const handleSaveClick = useCallback(() => {
    addRateMutation({
      variables: generateAddRateVariables(rate)
    })
  }, [addRateMutation, rate]);


  const updateRate = useCallback((key, value, secondKey = null, secondValue = null) => {
    let updatedRate = {
      ...rate,
      [key]: value,
    };

    if (secondKey) {
      updatedRate = {
        ...updatedRate,
        [secondKey]: secondValue
      }
    }

    setRate(updatedRate);
  }, [setRate, rate]);

  return (
    <>
      {addRate ? (
        <>
          <Button onClick={handleAddRateClick} disabled={submittingAddRate}>
            Cancel
          </Button>
          <Button onClick={handleSaveClick} disabled={submittingAddRate}>
            Save
          </Button>
        </>
      ) : (
        <Button onClick={handleAddRateClick}>
          Add Rate
        </Button>
      )}
      {addRate && <Form rate={rate} updateRate={updateRate} />}
    </>
  );
};

export default AddRate;
