import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import { withLayout } from './layout';

import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Upload from './pages/Upload';
import ManualUpload from './pages/ManualUpload';
import Quote from './pages/Quote';
import UploadLog from './pages/UploadLog';
import Rates from './pages/Rates';
import QuoteAddFields from './pages/QuoteAddFields';

const LoginRedirect = () => {
    return (
        <Redirect
            to={{
                pathname: `/login`,
            }}
        />
    );
};

const AuthRoute = ({ component: Component, ...rest }) => {
    const authCheck = () => {
        const tokenExists = localStorage.getItem('api_token');

        return !!tokenExists;
    };

    const returnRender = () => {
        if (authCheck()) {
            return withLayout(<Component />);
        } else {
            return <LoginRedirect />;
        }
    };

    return <Route {...rest} render={returnRender} />;
};

const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/login" render={() => <Login />} />
                <Route
                    exact
                    path="/forgot-password"
                    render={() => <ForgotPassword />}
                />
                <AuthRoute exact path="/" component={Home} />
                <AuthRoute
                    exact
                    path="/quote/upload-log"
                    component={UploadLog}
                />
                <AuthRoute exact path="/quote/upload" component={Upload} />
                <AuthRoute
                    exact
                    path="/quote/upload-manual"
                    component={ManualUpload}
                />
                <AuthRoute exact path="/quote/:id" component={Quote} />
                <AuthRoute
                    exact
                    path="/quote/:id/addFields"
                    component={QuoteAddFields}
                />
                <AuthRoute exact path="/rates" component={Rates} />
            </Switch>
        </Router>
    );
};

export default React.memo(App, () => true);
