import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    row: {
        backgroundColor: "rgba(0, 60, 0, 0.1)"
    }
}));

const resolveErrorMessage = (error) => {
    if (!error) return 'No error';

    return error;
}

const BatchDetails = ({ batch, isOpen }) => {
    const classes = useStyles();

    if (!isOpen) return null;

    return (
        <TableRow className={classes.row}>
            <TableCell colSpan={1}>Error:</TableCell>
            <TableCell colSpan={7}>{resolveErrorMessage(batch.error)}</TableCell>
        </TableRow>
    )
};

export default BatchDetails;